import { DeleteOutlined, InfoCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { AutoComplete, Collapse, Flex, Input, Select, Space, Tooltip, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { manualOperationsService } from "src/core/services/priceConfiguratorServices/manualOperationsService";
import { calculatePropertySum, calculateWeightedAverage } from "src/core/utils/array";
import { formatNumber } from "src/core/utils/format";
import { validateInput } from "src/core/utils/input";
import { updateOrAddObject } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/ManualAdjustments/utils/utils";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
    currentPriceConfiguratorState,
    currentUserState
} from "src/recoil/atoms";
import { IdPhaseObject, OptionItem, StringValue } from "src/types/common";
import { ManualOperationsDataItem } from "src/types/priceConfigurator";

type Props = {
    dataRefreshing?: boolean;
    onDataChange: () => Promise<void>;
};

const { Text } = Typography;
const { Panel } = Collapse;

const ManualOperationsTable: React.FC<Props> = (props: Props) => {
    const { dataRefreshing, onDataChange } = props;
    const { t } = useTranslation();

    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();
    const [unsavedRecord, setUnsavedRecord] = useState<ManualOperationsDataItem>();
    const [names, setNames] = useState<StringValue[]>();
    const [phases, setPhases] = useState<StringValue[]>();
    const [phaseLoading, setPhaseLoading] = useState<boolean>(false);
    const [selectedNamePhaseList, setSelectedNamePhaseList] = useState<IdPhaseObject[]>([]);

    const [dataItems, setDataItems] = useState<ManualOperationsDataItem[]>([]);
    const [costTypeMappings, setCostTypeMappings] = useState<OptionItem[]>([]);

    const currentPriceConfigurator = useRecoilValue(currentPriceConfiguratorState);
    const currentUser = useRecoilValue(currentUserState);

    const { accessToken, success, authLoaded } = useAuth();

    const handleInputFocus = async (name: string) => {
        setPhaseLoading(true);
        setPhases([]);

        const response = await manualOperationsService.fetchManualOperationsPhases(accessToken, currentPriceConfigurator.id, name);

        setPhases(response);
        setPhaseLoading(false);
    }

    useEffect(() => {
        (async () => {
            if (authLoaded && success) {
                setTableLoading(true);

                const response = await Promise.all([
                    manualOperationsService.fetchManualOperationsRecords(accessToken, currentPriceConfigurator.id),
                    manualOperationsService.fetchManualOperationsNames(accessToken, currentPriceConfigurator.id, "")
                ])
                const [manualOperationRecords, listOfNames] = response;

                setDataItems(manualOperationRecords);
                setNames(listOfNames);
                setTableLoading(false);
            }
        })();
    }, [
        accessToken,
        authLoaded,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        success,
    ]);

    useEffect(() => {
        (async () => {
            if (authLoaded && success) {
                if (costTypeMappings.length === 0) {
                    const response =
                        await metaDataService.fetchManualAdjustmentCostTypeMappings(accessToken);

                    setCostTypeMappings(response);
                }
            }
        })();
    }, [
        accessToken,
        authLoaded,
        costTypeMappings.length,
        success,
    ]);

    useEffect(() => {
        (async () => {
            // saves unsaved record then it is ready to be saved
            if (authLoaded && success && unsavedRecord) {
                setSavingUnsavedRecordId(unsavedRecord.id);
                setTableLoading(true);

                await manualOperationsService.saveManualOperationsRecord(
                    accessToken,
                    currentPriceConfigurator.id,
                    { ...unsavedRecord, currencyCode: currentUser.currency?.currencyCode }
                );

                const updatedRecords =
                    await manualOperationsService.fetchManualOperationsRecords(
                        accessToken,
                        currentPriceConfigurator.id,
                    );

                setDataItems(updatedRecords);

                setSavingUnsavedRecordId(undefined);
                setUnsavedRecord(undefined);
                setTableLoading(false);
            }
        })();
    }, [
        accessToken,
        authLoaded,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        currentUser.currency?.currencyCode,
        dataItems,
        onDataChange,
        success,
        unsavedRecord,
    ]);

    const columns: ColumnsType<ManualOperationsDataItem> = [
        {
            title: t("tableColumn.name"),
            key: "m_op_name_column",
            align: "center",
            width: 350,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    <AutoComplete
                        key={index}
                        defaultValue={item?.name}
                        options={names}
                        fieldNames={{ value: "value", label: "value" }}
                        style={{ width: "100%" }}
                        filterOption={true}
                        allowClear={true}
                        defaultActiveFirstOption={true}
                        onChange={async (_val) => {
                            // reseting the phase for record that's had its name changed
                            setSelectedNamePhaseList(updateOrAddObject(selectedNamePhaseList, item.id, "phase", ""));

                            const isInputInNamesList = names?.find((element) => element?.value?.toLowerCase() === _val?.toLowerCase());
                            if (_val?.length === 0 || isInputInNamesList === undefined) {
                                setPhases([]);
                                await saveRecord(item, "name", null, index, "", null, "", null, true);
                            }
                            if (isInputInNamesList) {
                                await saveRecord(item, "name", isInputInNamesList.value, index);
                            }
                        }}
                        onSelect={async (value) => {
                            if (value) {
                                await saveRecord(item, "name", value, index);
                            }
                        }}
                        onClear={async () => {
                            await saveRecord(item, "name", null, index, "", null, "", null, true);
                        }}
                    />
                );
            }
        },
        {
            title: t("tableColumn.phase"),
            key: "m_op_phase_column",
            align: "center",
            width: 110,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                const isItemInNamePhaseList = selectedNamePhaseList.find((e: any) => e.id === item.id)
                return (
                    <AutoComplete
                        key={index}
                        defaultValue={value?.phase}
                        value={isItemInNamePhaseList && isItemInNamePhaseList?.phase}
                        options={phases}
                        style={{ width: "100%" }}
                        fieldNames={{ value: "value", label: "value" }}
                        disabled={item?.name === null || (item.name !== null && item?.name?.length === 0)}
                        defaultActiveFirstOption={true}
                        popupMatchSelectWidth={100}
                        allowClear={true}
                        notFoundContent={
                            <Flex justify="center">
                                {phaseLoading ?
                                    <Spinner size="small" />
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <span>{t("message.noData")}</span>
                                    </div>
                                }
                            </Flex>

                        }
                        onFocus={() => {
                            if (item.name) {
                                handleInputFocus(item?.name);
                            }
                            else {
                                return;
                            }
                        }}
                        onChange={async (_val) => {
                            setSelectedNamePhaseList(updateOrAddObject(selectedNamePhaseList, item.id, "phase", _val));

                            const isInputInPhasesList = phases?.find((element) => element?.value?.toLowerCase() === _val?.toLowerCase());
                            if (_val?.length === 0 || isInputInPhasesList === undefined || isItemInNamePhaseList?.phase?.length === 0) {
                                await saveRecord(item, "phase", null, index, "name", item.name, "", null, true);
                            }
                            if (isInputInPhasesList) {
                                await saveRecord(item, "phase", isInputInPhasesList.value, index);
                            }
                        }}
                        onSelect={async (value) => {
                            await saveRecord(item, "phase", value, index, "name", item.name);
                        }}
                        onClear={async () => {
                            await saveRecord(item, "phase", null, index, "name", item.name, "", null, true);
                        }}
                    />
                )
            },
        },
        {
            title: t("tableColumn.mainLayer"),
            key: "m_op_main_layer_column",
            align: "center",
            width: 120,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item.mainLayer ? <span>{item?.mainLayer}</span> : null
                )
            },
        },
        {
            title: t("tableColumn.specificLayer"),
            key: "m_op_specific_layer_column",
            align: "center",
            width: 150,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item.specificLayer ? <span>{item?.specificLayer}</span> : null
                )
            },
        },
        {
            title: t("tableColumn.WRKCDescription"),
            key: "m_op_wkrc_description_column",
            align: "center",
            width: 160,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item.workCenter ? <span>{item?.workCenter}</span> : null
                )
            },
        },
        {
            title: t("tableColumn.costTypeMapping"),
            key: "m_op_cost_type_m_op_column",
            align: "center",
            width: 100,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                const val = item.id > 0 ? item.costTypeMapping : unsavedRecord?.costTypeMapping;
                return (
                    <Select
                        value={val}
                        style={{ width: "100%" }}
                        fieldNames={{ value: "label", label: "label" }}
                        onChange={async (selectedVal: string, option: OptionItem | OptionItem[]) => {
                            if (selectedVal === "L" || selectedVal === "P") {
                                await saveRecord(
                                    item,
                                    "costTypeMapping",
                                    selectedVal,
                                    index,
                                    "father",
                                    "Finishing",
                                    "component",
                                    "Finishing"
                                );
                            } else {
                                await saveRecord(item, "costTypeMapping", selectedVal, index);
                            }
                        }}
                        options={costTypeMappings}
                    />
                )
            },
        },
        {
            title: t("tableColumn.father"),
            key: "m_op_father_column",
            align: "center",
            width: 100,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                const val = item.id > 0 ? item.father : unsavedRecord?.father;
                return (
                    <Input
                        value={val}
                        style={{ width: "100%" }}
                        title={t("tableColumn.father") as string}
                        name={t("tableColumn.father") as string}
                        status={!validateInput(val as string) ? "error" : undefined}
                        onChange={async (_ev) => {
                            await saveRecord(item, "father", _ev.target.value, index);
                        }}
                        suffix={
                            !validateInput(val as string) ?
                                <Tooltip title={t("message.inputError")}
                                    defaultOpen
                                    placement="right"
                                    overlayStyle={{ fontSize: "12px" }}
                                >
                                    <InfoCircleOutlined style={{ color: 'red' }} />
                                </Tooltip>
                                : <span />
                        }
                    />
                )
            },
        },
        {
            title: t("tableColumn.component"),
            key: "m_op_component_column",
            align: "center",
            width: 100,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                const val = item.id > 0 ? item.component : unsavedRecord?.component;
                return (
                    <Input
                        value={val}
                        style={{ width: "100%" }}
                        title={t("tableColumn.component") as string}
                        name={t("tableColumn.component") as string}
                        status={!validateInput(val as string) ? "error" : undefined}
                        onChange={async (_ev) => {
                            await saveRecord(item, "component", _ev.target.value, index);
                        }}
                        suffix={
                            !validateInput(val as string) ?
                                <Tooltip title={t("message.inputError")}
                                    defaultOpen
                                    placement="right"
                                    overlayStyle={{ fontSize: "12px" }}
                                >
                                    <InfoCircleOutlined style={{ color: 'red' }} />
                                </Tooltip>
                                : <span />
                        }
                    />
                )
            },
        },
        {
            title: t("tableColumn.pureTime"),
            key: "m_op_pure_time_column",
            align: "center",
            width: 90,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item?.pureTime !== 0 ?
                        <span>{item?.pureTime}</span>
                        : (item?.pureTime === 0 ? "0.00" : null)
                )
            },
        },
        {
            title: t("tableColumn.efficiency"),
            key: "m_op_efficiency_column",
            align: "center",
            width: 90,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item?.efficiency !== null && item?.efficiency !== 0 ?
                        <span>{item?.efficiency}%</span>
                        : (item?.efficiency === 0 ? "0%" : null)
                )
            },
        },
        {
            title: t("tableColumn.costDl"),
            key: "m_op_cost_Dl_column",
            align: "center",
            width: 90,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item?.dl !== null && item?.dl !== 0 ?
                        <ValueWithCurrency
                            value={item?.dl}
                            currencyCode={item?.currencyCode}
                            showEmptyIfNullOrZero
                        />
                        : (item?.dl === 0 ? "0.00" : null)
                )
            },
        },
        {
            title: t("tableColumn.costFov"),
            key: "m_op_cost_Fov_column",
            align: "center",
            width: 90,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item?.fov !== null && item?.fov !== 0 ?
                        <ValueWithCurrency
                            value={item?.fov}
                            currencyCode={item?.currencyCode}
                            showEmptyIfNullOrZero
                        />
                        : (item?.fov === 0 ? "0.00" : null)
                )
            },
        },
        {
            title: t("tableColumn.costFof1"),
            key: "m_op_cost_Fof1_column",
            align: "center",
            width: 90,
            render: (
                value: ManualOperationsDataItem,
                item: ManualOperationsDataItem,
                index: number
            ) => {
                return (
                    item?.fof1 !== null && item?.fof1 !== 0 ?
                        <ValueWithCurrency
                            value={item?.fof1}
                            currencyCode={item?.currencyCode}
                            showEmptyIfNullOrZero
                        />
                        : (item?.fof1 === 0 ? "0.00" : null)
                )
            },
        },
        //DELETE
        {
            key: "manual-input-actions",
            width: 20,
            fixed: "right",
            align: "center",
            render: (_, record) => (
                <Space size="middle">
                    {record.id === savingUnsavedRecordId && <Spinner size="small" />}
                    {record.id !== savingUnsavedRecordId && (
                        <Tooltip title={t("button.delete")} overlayStyle={{ fontSize: "12px" }}>
                            <DeleteOutlined
                                style={{ cursor: "pointer" }}
                                onClick={async () => {
                                    if (record.id > 0) {
                                        setSavingUnsavedRecordId(record.id);

                                        await manualOperationsService.deleteManualOperationsRecord(
                                            accessToken,
                                            currentPriceConfigurator.id,
                                            record.id
                                        );

                                        setTableLoading(true);

                                        setDataItems(dataItems.filter((item) => item.id !== record.id));

                                        setSavingUnsavedRecordId(undefined);
                                        setTableLoading(false);

                                        await onDataChange();
                                    } else {
                                        deleteUnsavedRecord();
                                    }
                                }}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const deleteUnsavedRecord = () => {
        setUnsavedRecord(undefined);
    };

    const saveRecord = async (
        record: ManualOperationsDataItem,
        property: string,
        value: string | number | undefined | null,
        index: number,
        property2?: string,
        value2?: string | number | undefined | null,
        property3?: string,
        value3?: string | number | undefined | null,
        clearData?: boolean
    ) => {
        setSavingUnsavedRecordId(record.id);

        const recordUpdateData = property2
            ? property3
                ? {
                    ...record,
                    [property]: value,
                    [property2]: value2,
                    [property3]: value3,
                }
                : { ...record, [property]: value, [property2]: value2 }
            : { ...record, [property]: value };

        if (record.id > 0) {
            // DB record update
            let updatedDataItems = [...dataItems];

            const strippedObject: any = Object.entries(recordUpdateData)
                .filter(([key, value]) => value !== null)
                .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

            if (clearData) {
                strippedObject.phase =
                    strippedObject.mainLayer =
                    strippedObject.currencyCode =
                    strippedObject.dl =
                    strippedObject.efficiency =
                    strippedObject.fof1 =
                    strippedObject.fov =
                    strippedObject.mainLayer =
                    strippedObject.specificLayer =
                    strippedObject.pureTime =
                    strippedObject.workCenter = undefined;
            }
            updatedDataItems.splice(index, 1, recordUpdateData);

            setDataItems(updatedDataItems);

            // TODO: add loading logic for when column data is being fetched
            if (validateInput(value as string)) {

                const response = await manualOperationsService.updateManualOperationsRecord(
                    accessToken,
                    currentPriceConfigurator.id,
                    record.id,
                    strippedObject
                );

                let ax = [...dataItems]

                let indexToFind = ax.findIndex(obj => obj.id === response.id);

                if (indexToFind !== -1) {
                    ax[indexToFind] = response;
                }
                setDataItems(ax);
            }
        } else {
            if (unsavedRecord) {
                setUnsavedRecord(recordUpdateData);
            }
        }

        setSavingUnsavedRecordId(undefined);
    };

    const handleAddClick = () => {
        const newRecord: ManualOperationsDataItem = {
            id: -1,
            isCorrection: true,
        };

        setDataItems([...dataItems, ...[newRecord]]);
        setUnsavedRecord(newRecord);
    };
    return (
        <Space direction="vertical" className="full-width-space" style={{ marginTop: 50 }}>
            <Panel
                header={<Title level={3}>{t("label.manualOperations")}</Title>}
                key="manual-operations"
            />
            <PlusSquareOutlined
                disabled={unsavedRecord !== undefined}
                onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
                style={{
                    width: 24,
                    height: 24,
                    fontSize: 24,
                    color: unsavedRecord !== undefined ? "gray" : "black",
                    marginBottom: 10,
                    float: "left",
                }}
            />
            <Table
                columns={columns}
                loading={tableLoading || dataRefreshing}
                dataSource={dataItems}
                pagination={false}
                rowKey={({ id }) => id}
                scroll={{ x: "max-content" }}
                bordered={true}
                size={"small"}
                summary={(tableData) => {
                    let totals: any = {};

                    if (tableData.length > 0) {
                        totals = {
                            pureTime: formatNumber(calculatePropertySum([...tableData], "pureTime"), 2),
                            efficiency: formatNumber(
                                calculateWeightedAverage([...tableData], "efficiency", "pureTime"), 2),
                            costDL: calculateCostPropertySum(
                                [...tableData],
                                "dl",
                                currentPriceConfigurator.currencies ?? [],
                                currentUser.currency?.currencyCode ?? ""
                            ),
                            costFov: calculateCostPropertySum(
                                [...tableData],
                                "fov",
                                currentPriceConfigurator.currencies ?? [],
                                currentUser.currency?.currencyCode ?? ""
                            ),
                            costFof1: calculateCostPropertySum(
                                [...tableData],
                                "fof1",
                                currentPriceConfigurator.currencies ?? [],
                                currentUser.currency?.currencyCode ?? ""
                            ),
                        }
                    }

                    return (
                        <Table.Summary.Row style={{ textAlign: "center" }}>

                            <Table.Summary.Cell colSpan={8} index={0}>
                                <Text style={{ float: "right", fontWeight: 600, marginRight: 20 }}>
                                    {t("tableFooter.total")}
                                </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                {tableData && tableData.length > 0 && (
                                    <span>{totals.pureTime}</span>
                                )}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                {tableData && tableData.length > 0 && (
                                    <span>{totals.efficiency}%</span>
                                )}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                {tableData && tableData.length > 0 && (
                                    <ValueWithCurrency
                                        value={totals.costDL}
                                        currencyCode={currentUser.currency?.currencyCode}
                                        showForceZeroIfZero={true}
                                        showZeroIfNull={true}
                                    />
                                )}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                {tableData && tableData.length > 0 && (
                                    <ValueWithCurrency
                                        value={totals.costFov}
                                        currencyCode={currentUser.currency?.currencyCode}
                                        showForceZeroIfZero={true}
                                        showZeroIfNull={true}
                                    />
                                )}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={1}>
                                {tableData && tableData.length > 0 && (
                                    <ValueWithCurrency
                                        value={totals.costFof1}
                                        currencyCode={currentUser.currency?.currencyCode}
                                        showForceZeroIfZero={true}
                                        showZeroIfNull={true}
                                    />
                                )}
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    );
                }} />
        </Space>
    )
}

export default ManualOperationsTable
