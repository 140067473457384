import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import {
  MergePriceConfiguratorsData,
  RecapSectionData,
} from "src/types/priceConfigurator";

const createMergeService = () => {
  const mergePriceConfigurators = async (
    accessToken: string,
    data: MergePriceConfiguratorsData
  ): Promise<void> => {
    await post<RecapSectionData>(
      accessToken,
      API_BASE_URL + `/v1/priceConfigurators/merge`,
      data
    );
  };

  return {
    mergePriceConfigurators,
  };
};
export const mergeService = createMergeService();
