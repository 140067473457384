import { Space } from "antd";
import { useEffect, useState } from "react";
import { DataSectionAliasHandler } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/DataSectionAliasHandler";
import { TabContent } from "src/modules/PriceConfiguratorManager/components/DataSectionTabsManager/components/TabContent";
import {
  DataSectionAliasData,
  DataSectionData,
} from "src/types/priceConfigurator";

type Props = {
  aliasData: DataSectionAliasData;
  dataSection: DataSectionData;
};

export const DataSectionAlias = (props: Props) => {
  const { dataSection, aliasData } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  return (
    <TabContent>
      <Space direction="vertical" size={0} className="full-width-space">
        <DataSectionAliasHandler
          aliasData={aliasData}
          dataSection={dataSection}
        />
      </Space>
    </TabContent>
  );
};
