import { MenuOutlined } from "@ant-design/icons";
import { Drawer, FloatButton, Image, Layout, Skeleton, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import LogoBlack from "src/assets/imgs/logo-black.png";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { useAppEvents } from "src/core/hooks/useAppEvents/useAppEvents";
import { useAppFeature } from "src/core/hooks/useAppFeature";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { AppFeatureEnum } from "src/enums";
import { AdminPageContent } from "src/modules/AdminPage/components/AdminPageContent";
import { AdminSidebar, AppPage } from "src/modules/AdminSidebar/AdminSidebar";
import { AdminTopBar } from "src/modules/AdminTopBar/AdminTopBar";
import { KnowledgeChat } from "src/modules/KnowledgeChat/KnowledgeChat";
import { currentUserState, defaultAppDataState } from "src/recoil/atoms";
import { Currency } from "src/types/common";

const { Header } = Layout;

type Props = {
  pageTitle: string;
  activePage?: AppPage;
  headerButtons?: JSX.Element[];
  children: JSX.Element | JSX.Element[];
  contentLoading?: boolean;
  pageToAddChilds?: AppPage;
  childSidebarItems?: SidebarItem[];
  centerTitle?: string;
};

export const AdminPage: React.FC<Props> = (props: Props) => {
  const {
    children,
    activePage,
    pageTitle,
    headerButtons,
    contentLoading,
    pageToAddChilds,
    childSidebarItems,
    centerTitle,
  } = props;

  const defaultAppData = useRecoilValue(defaultAppDataState);

  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const isMobile = useViewPortQuery() === "mobile";

  const { enabled: knowLedgeBaseChat } = useAppFeature(
    AppFeatureEnum.KNOWLEDGEBASECHATFEATURE,
    false
  );

  const { t } = useTranslation();
  useAppEvents();

  useEffect(() => {
    (async () => {
      if (currentUser) {
        if (!currentUser.currency) {
          setLoading(true);

          var defaultCurrency = defaultAppData.activeCurrencies.find(
            (currency: Currency) => currency.isDefault
          );

          if (defaultCurrency) {
            setCurrentUser({ ...currentUser, currency: defaultCurrency });
          }
        }

        setLoading(false);
      }
    })();
  }, [currentUser, defaultAppData.activeCurrencies, setCurrentUser]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Skeleton style={{ padding: 20 }} loading={loading} active={true}>
        <Layout>
          <Header
            style={{
              padding: 0,
              position: "fixed",
              top: 0,
              width: "100%",
              zIndex: 999,
              background: "#000000",
            }}
          >
            <AdminTopBar
              centerTitle={centerTitle}
              showOnlyLogo={isMobile}
              preNameBlock={
                isMobile ? (
                  <MenuOutlined
                    style={{
                      color: "white",
                      fontSize: 22,
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenDrawer(!openDrawer)}
                  />
                ) : undefined
              }
            />
          </Header>
          <Layout hasSider style={{ marginTop: 64 }}>
            {isMobile && (
              <Drawer
                placement="left"
                width={220}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                styles={{
                  header: { padding: "5px 8px 3px 8px" },
                  body: {
                    paddingTop: 5,
                    paddingRight: 0,
                    paddingLeft: 0,
                    backgroundColor: "#fffbf9",
                  },
                }}
                className="drawer-close-icon-right"
                title={
                  <Space size={0}>
                    <Image
                      src={LogoBlack}
                      width={20}
                      style={{ marginBottom: 5 }}
                      preview={false}
                    />
                    <Title level={5} style={{ marginLeft: 5, marginTop: 5 }}>
                      {t("common.appName")}
                    </Title>
                  </Space>
                }
              >
                <AdminSidebar
                  activePage={activePage}
                  pageToAddChilds={pageToAddChilds}
                  childSidebarItems={childSidebarItems}
                  collapsible={false}
                  isInDrawer={true}
                />
              </Drawer>
            )}
            {!isMobile && (
              <AdminSidebar
                activePage={activePage}
                pageToAddChilds={pageToAddChilds}
                childSidebarItems={childSidebarItems}
              />
            )}
            {knowLedgeBaseChat && <KnowledgeChat />}
            <AdminPageContent
              pageTitle={pageTitle}
              children={children}
              activePage={activePage}
              headerButtons={headerButtons}
              contentLoading={contentLoading}
              pageToAddChilds={pageToAddChilds}
              childSidebarItems={childSidebarItems}
              centerTitle={centerTitle}
              loading={loading}
            />
          </Layout>
        </Layout>
        <FloatButton.BackTop />
      </Skeleton>
    </Layout>
  );
};
