import { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import StickyTableColumn from "src/core/components/StickyTableColumn";
import { formatNumber } from "src/core/utils/format";
import { getGroupedCellRowSpan } from "src/core/utils/table";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { RouteDataItem } from "src/types/priceConfigurator";

export function useRouteDataTableColumns(dataItems: RouteDataItem[]) {
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const columns: ColumnsType<RouteDataItem> = [
      {
        title: t("tableColumn.father"),
        key: "route-father",
        align: "center",
        width: 100,
        render: (_, record) => (
          <StickyTableColumn>{record.father}</StickyTableColumn>
        ),
        onCell: (record: RouteDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "father",
          ]),
        }),
      },
      {
        title: t("tableColumn.fatherCode"),
        key: "route-fatherCode",
        align: "center",
        width: 100,
        render: (_, record) => (
          <StickyTableColumn>{record.fatherCode}</StickyTableColumn>
        ),
        onCell: (record: RouteDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "father",
            "fatherCode",
          ]),
        }),
      },
      {
        title: t("tableColumn.component"),
        key: "route-component",
        align: "center",
        width: 100,
        render: (_, record) => (
          <StickyTableColumn>{record.component}</StickyTableColumn>
        ),
        onCell: (record: RouteDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "father",
            "fatherCode",
            "component",
          ]),
        }),
      },

      {
        title: t("tableColumn.componentCode"),
        key: "route-component-code",
        align: "center",
        width: 80,
        render: (_, record) => (
          <StickyTableColumn>{record.componentCode}</StickyTableColumn>
        ),
        onCell: (record: RouteDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "father",
            "fatherCode",
            "component",
            "componentCode",
          ]),
        }),
      },
      {
        title: t("tableColumn.componentDescription"),
        key: "route-component-description",
        align: "center",
        width: 150,
        render: (_, record) => (
          <StickyTableColumn>{record.componentDescription}</StickyTableColumn>
        ),
        onCell: (record: RouteDataItem, index: number | undefined) => ({
          rowSpan: getGroupedCellRowSpan(index ?? 0, record, dataItems, [
            "father",
            "fatherCode",
            "component",
            "componentCode",
            "componentDescription",
          ]),
        }),
      },
      {
        title: t("tableColumn.mainLayer"),
        key: "route-mainLayer",
        align: "center",
        width: 80,
        render: (_, record) => (
          <StickyTableColumn>{record.mainLayer}</StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.singleLayer"),
        key: "route-singleLayer",
        align: "center",
        width: 80,
        render: (_, record) => (
          <StickyTableColumn>{record.singleLayer}</StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.operation"),
        key: "route-operation",
        align: "center",
        width: 100,
        render: (_, record) => (
          <StickyTableColumn>{record.operation}</StickyTableColumn>
        ),
      },
      {
        title: t("tableColumn.pureTimeInMin"),
        key: "route-pure-time-in-min",
        width: 80,
        align: "center",
        render: (_, record) => formatNumber(record.pureTime),
      },
      {
        title: t("tableColumn.costDl"),
        key: "route-cost-dl",
        align: "center",
        width: 80,
        className: "gray-background-column",
        render: (_, record) => (
          <ValueWithCurrency
            value={record.costDl}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.costFov"),
        key: "route-cost-fov",
        align: "center",
        width: 80,
        render: (_, record) => (
          <ValueWithCurrency
            value={record.costFov}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.costFof1"),
        key: "route-cost-fof",
        align: "center",
        width: 80,
        render: (_, record) => (
          <ValueWithCurrency
            value={record.costFof}
            currencyCode={record.currencyCode}
          />
        ),
      },
      {
        title: t("tableColumn.totalCost"),
        key: "route-total-cost",
        align: "center",
        width: 80,
        render: (_, record) => (
          <ValueWithCurrency
            value={record.totalCost}
            currencyCode={record.currencyCode}
          />
        ),
      },
    ];

    return columns;
  }, [dataItems, t]);

  return {
    getTableColumns: getTableColumns,
  };
}
