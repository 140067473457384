import { DeleteOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { InputNumber, Select, Space, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { getValueInSpecificCurrency } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
  dataSectionContextState,
  masterInfoState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";
import { SunLensesManualDataItem } from "src/types/priceConfigurator";

type Props = {
  dataSectionId: number;
  dataRefreshing?: boolean;
  onDataChange: () => Promise<void>;
};

const { Text } = Typography;

export const SunLensesManualOrderFormTable = (props: Props) => {
  const { dataRefreshing, onDataChange, dataSectionId } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const [unsavedRecord, setUnsavedRecord] = useState<SunLensesManualDataItem>();
  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [dataItems, setDataItems] = useState<SunLensesManualDataItem[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const masterInfoData = useRecoilValue(masterInfoState);
  const currentUser = useRecoilValue(currentUserState);
  const [dataSectionContext, setDataSectionContext] = useRecoilState(
    dataSectionContextState
  );

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableLoading(true);

        const response =
          await manualDataService.fetchSunLensesManualInputRecords(
            accessToken,
            currentPriceConfigurator.id,
            dataSectionId
          );

        setDataItems(response);

        if (dataSectionContext.manualMadeIns?.length === 0) {
          const madeIns =
            await metaDataService.fetchManualAdjustmentMadeInComponents(
              accessToken
            );

          setDataSectionContext((prevState) => {
            return { ...prevState, manualMadeIns: madeIns };
          });
        }

        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    dataSectionContext.manualMadeIns?.length,
    dataSectionId,
    setDataSectionContext,
    success,
  ]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success && unsavedRecord) {
        setSavingUnsavedRecordId(unsavedRecord.id);
        setTableLoading(true);

        await manualDataService.createSunLensesManualInputRecord(
          accessToken,
          currentPriceConfigurator.id,
          dataSectionId
        );

        const response =
          await manualDataService.fetchSunLensesManualInputRecords(
            accessToken,
            currentPriceConfigurator.id,
            dataSectionId
          );

        setDataItems(response);

        setSavingUnsavedRecordId(undefined);
        setUnsavedRecord(undefined);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentUser.currency?.currencyCode,
    dataSectionId,
    success,
    unsavedRecord,
  ]);

  const columns: ColumnsType<SunLensesManualDataItem> = [
    {
      title: t("tableColumn.sunLensSku"),
      key: "m_sku_column",
      align: "center",
      width: 80,
      render: (
        value: SunLensesManualDataItem,
        item: SunLensesManualDataItem,
        index: number
      ) => (
        <InputNumber
          value={item.id > 0 ? item.sku : unsavedRecord?.sku}
          style={{ width: "100%" }}
          min={1}
          onChange={async (value: number | null) => {
            await saveRecord(item, "sku", value ?? undefined, index);
          }}
        />
      ),
    },
    {
      title: t("tableColumn.madeIn"),
      key: "m_made_in_column",
      align: "center",
      width: 100,
      render: (
        value: SunLensesManualDataItem,
        item: SunLensesManualDataItem,
        index: number
      ) => (
        <Select
          value={item.id > 0 ? item.madeInId : unsavedRecord?.madeInId}
          style={{ width: "100%" }}
          fieldNames={{ value: "id", label: "label" }}
          onChange={async (_val: number, option: OptionItem | OptionItem[]) => {
            await saveRecord(item, "madeInId", _val, index);
          }}
          options={masterInfoData.madeIns}
        />
      ),
    },
    {
      title: t("tableColumn.lensMaterial"),
      key: "m_lens_material_column",
      align: "center",
      width: 100,
      render: (
        value: SunLensesManualDataItem,
        item: SunLensesManualDataItem,
        index: number
      ) => (
        <Select
          value={item.id > 0 ? item.material : unsavedRecord?.material}
          style={{ width: "100%" }}
          fieldNames={{ value: "label", label: "label" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            await saveRecord(item, "material", _val, index);
          }}
          options={masterInfoData?.manualLensMaterials}
        />
      ),
    },
    {
      title: t("tableColumn.lensType"),
      key: "m_type_column",
      align: "center",
      width: 100,
      render: (
        value: SunLensesManualDataItem,
        item: SunLensesManualDataItem,
        index: number
      ) => (
        <Select
          value={item.id > 0 ? item.type : unsavedRecord?.type}
          style={{ width: "100%" }}
          fieldNames={{ value: "label", label: "label" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            await saveRecord(item, "type", _val, index);
          }}
          options={masterInfoData?.lensTypes}
        />
      ),
    },
    {
      title: t("tableColumn.photochromic"),
      key: "m_photochromic_column",
      align: "center",
      width: 100,
      render: (
        value: SunLensesManualDataItem,
        item: SunLensesManualDataItem,
        index: number
      ) => (
        <Select
          allowClear
          value={item.id > 0 ? item.photochromic : unsavedRecord?.photochromic}
          style={{ width: "100%" }}
          fieldNames={{ value: "label", label: "label" }}
          onChange={async (_val: string, option: OptionItem | OptionItem[]) => {
            await saveRecord(item, "photochromic", _val, index);
          }}
          options={masterInfoData?.photochromic}
        />
      ),
    },
    {
      title: t("tableColumn.lensCost"),
      key: "m_cost_column",
      align: "center",
      width: 120,
      render: (item: SunLensesManualDataItem) => (
        <ValueWithCurrency
          value={item.cost}
          currencyCode={item.currencyCode}
          showEmptyIfNullOrZero={true}
          showNotAvailableIfNull={true}
        />
      ),
    },
    {
      key: "manual-input-actions",
      width: 20,
      fixed: "right",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {record.id === savingUnsavedRecordId && <Spinner size="small" />}
          {record.id !== savingUnsavedRecordId && (
            <DeleteOutlined
              onClick={async () => {
                if (record.id > 0) {
                  setSavingUnsavedRecordId(record.id);

                  await manualDataService.deleteSunLensesManualInputRecord(
                    accessToken,
                    currentPriceConfigurator.id,
                    record.id
                  );

                  setTableLoading(true);

                  setDataItems(
                    dataItems.filter((item) => item.id !== record.id)
                  );

                  setSavingUnsavedRecordId(undefined);
                  setTableLoading(false);

                  await onDataChange();
                } else {
                  deleteUnsavedRecord();
                }
              }}
              style={{ cursor: "pointer" }}
            />
          )}
        </Space>
      ),
    },
  ];

  const deleteUnsavedRecord = () => {
    setDataItems(dataItems.filter((item) => item.id > 0));
    setUnsavedRecord(undefined);
  };

  const saveRecord = async (
    record: SunLensesManualDataItem,
    property: string,
    value: string | number | undefined | null,
    index: number,
    property2?: string,
    value2?: string | number | undefined | null,
    property3?: string,
    value3?: string | number | undefined | null
  ) => {
    const recordUpdateData = property2
      ? property3
        ? {
            ...record,
            [property]: value,
            [property2]: value2,
            [property3]: value3,
          }
        : { ...record, [property]: value, [property2]: value2 }
      : { ...record, [property]: value };

    if (record.id > 0) {
      // DB record update
      let updatedDataItems = [...dataItems];

      updatedDataItems.splice(index, 1, recordUpdateData);
      setDataItems(updatedDataItems);

      if (
        recordUpdateData.sku &&
        recordUpdateData.madeInId &&
        recordUpdateData.material &&
        recordUpdateData.type
      ) {
        setSavingUnsavedRecordId(record.id);

        await manualDataService.updateSunLensesManualInputRecord(
          accessToken,
          currentPriceConfigurator.id,
          dataSectionId,
          record.id,
          recordUpdateData
        );

        const updatedRecord =
          await manualDataService.fetchSunLensesManualInputRecord(
            accessToken,
            currentPriceConfigurator.id,
            dataSectionId,
            record.id
          );

        updatedDataItems = [...dataItems];

        updatedDataItems.splice(index, 1, updatedRecord);
        setDataItems(updatedDataItems);

        setSavingUnsavedRecordId(undefined);

        await onDataChange();
      }
    } else {
      if (unsavedRecord) {
        setUnsavedRecord(recordUpdateData);
      }
    }
  };

  const handleAddClick = () => {
    const newRecord: SunLensesManualDataItem = {
      id: -1,
      isCorrection: true,
    };

    setDataItems([...dataItems, ...[newRecord]]);
    setUnsavedRecord(newRecord);
  };

  return (
    <Space direction="vertical" className="full-width-space">
      <PlusSquareOutlined
        disabled={unsavedRecord !== undefined}
        onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
        style={{
          width: 24,
          height: 24,
          fontSize: 24,
          color: unsavedRecord !== undefined ? "gray" : "black",
          marginBottom: 10,
          float: "left",
        }}
      />

      <Table
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        summary={(tableData) => {
          let averageCost = 0;
          let totalSku = 0;

          if (tableData.length > 0) {
            tableData.forEach((record) => {
              if ((record.cost ?? null) != null) {
                averageCost +=
                  getValueInSpecificCurrency(
                    record.cost,
                    record.currencyCode,
                    currentPriceConfigurator.currencies,
                    currentUser.currency?.currencyCode
                  ) * (record.sku ?? 0);

                totalSku += record.sku ?? 0;
              }
            });

            if (totalSku !== 0) {
              averageCost = averageCost / totalSku;
            }
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={5} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.averageCost")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData && tableData.length > 0 && (
                  <ValueWithCurrency
                    value={averageCost}
                    currencyCode={currentUser.currency?.currencyCode}
                    showZeroIfNull={true}
                    showForceZeroIfZero={true}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </Space>
  );
};
