import { Space } from "antd";
import Table, { TableProps } from "antd/es/table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { usePriceConfiguratorTableColumns } from "src/modules/PriceConfiguratorsTable/hooks/usePriceConfiguratorTableColumns";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

type Props = {
  data: PriceConfiguratorData[];
  onDelete: (item: PriceConfiguratorData) => Promise<void>;
  onDuplicate: (id: number) => Promise<void>;
  onQuickViewClick?: (id: number, modelNumber: string) => Promise<void>;
  onReference: (id: number) => Promise<void>;
  loading: boolean;
};

type TablePagination<T extends object> = NonNullable<
  Exclude<TableProps<T>["pagination"], boolean>
>;
type TablePaginationPosition = NonNullable<
  TablePagination<any>["position"]
>[number];

export const PriceConfiguratorsTable: React.FC<Props> = (props: Props) => {
  const {
    data,
    loading,
    onDelete,
    onDuplicate,
    onQuickViewClick,
    onReference,
  } = props;

  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [bottom, setBottom] = useState<TablePaginationPosition>("bottomRight");

  const navigate = useNavigate();

  const [currentPriceConfigurator, setCurrentPriceConfigurator] =
    useRecoilState(currentPriceConfiguratorState);

  useEffect(() => {
    (async () => {
      setTableLoading(false);
    })();
  }, []);

  const onEditClick = (id: number) => {
    setCurrentPriceConfigurator({ id: id, modelNumber: "" });
    navigate("/price-configurator/manager/master-info");
  };

  const { getTableColumns } = usePriceConfiguratorTableColumns(
    onDelete,
    onEditClick,
    onReference,
    onDuplicate,
    setTableLoading
  );

  return (
    <Space direction="vertical" className="full-width-space">
      <Table
        columns={getTableColumns()}
        bordered
        loading={loading || tableLoading}
        dataSource={data}
        onRow={(record: PriceConfiguratorData) => {
          return {
            onClick: () => {
              if (onQuickViewClick) {
                onQuickViewClick(record.id, record.modelNumber);
              }
            },
          };
        }}
        pagination={{ hideOnSinglePage: true, position: [bottom] }}
        rowKey={({ id }) => `price-configurator-${id}`}
        style={{ cursor: "pointer" }}
        scroll={{ x: "max-content" }}
        rowClassName={(record: PriceConfiguratorData) => {
          if (currentPriceConfigurator.id === record.id) {
            return "active-price-configurator";
          } else {
            return "";
          }
        }}
      />
    </Space>
  );
};
