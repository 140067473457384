import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { packagingService } from "src/core/services/priceConfiguratorServices/packagingService";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { calculateCostPropertySum } from "src/modules/ValueWithCurrency/utils/utils";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { PackagingSkuDataItem } from "src/types/priceConfigurator";

type Props = {
  modelNumber: string | undefined;
  dataRefreshing?: boolean;
  setSelectedValType: (value: string | undefined) => void;
};

const { Text } = Typography;

export const PackagingSkuSelectionTable = (props: Props) => {
  const { dataRefreshing, setSelectedValType, modelNumber } = props;

  const [tableLoading, setTableLoading] = useState(false);

  const [dataItems, setDataItems] = useState<PackagingSkuDataItem[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success && modelNumber) {
        setTableLoading(true);

        const response = await packagingService.fetchPackagingSkuDataItems(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          modelNumber
        );

        setDataItems(response);

        setTableLoading(false);

        if (response.length > 0) {
          const selectedItem = response.find((item) => item.isSelected);

          setSelectedValType(selectedItem?.valType);
        } else {
          setSelectedValType(undefined);
        }
      } else {
        setDataItems([]);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    modelNumber,
    setSelectedValType,
    success,
  ]);

  useEffect(() => {
    (async () => {
      const preselectedItems: PackagingSkuDataItem[] = dataItems.filter(
        (x: PackagingSkuDataItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: PackagingSkuDataItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
    })();
  }, [dataItems]);

  const columns: ColumnsType<PackagingSkuDataItem> = [
    {
      title: t("tableColumn.year"),
      key: "p_year_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.year,
    },
    {
      title: t("tableColumn.plant"),
      key: "p_plant_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.plant,
    },
    {
      title: t("tableColumn.brand"),
      key: "p_brand_column",
      align: "center",
      render: (item: PackagingSkuDataItem) => item.brand,
    },
    {
      title: t("tableColumn.model"),
      key: "p_model_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.model,
    },
    {
      title: t("tableColumn.valType"),
      key: "p_val_type_column",
      align: "center",
      render: (item: PackagingSkuDataItem) => item.valType,
    },
    {
      title: t("tableColumn.collection"),
      key: "p_collection_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.collection,
    },
    {
      title: t("tableColumn.material"),
      key: "p_material_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.material,
    },
    {
      title: t("tableColumn.release"),
      key: "p_release_column",
      align: "center",
      width: 100,
      render: (item: PackagingSkuDataItem) => item.release,
    },
    {
      title: t("tableColumn.pkgCost"),
      key: "p_kit_cost_column",
      align: "center",
      width: 80,
      render: (item: PackagingSkuDataItem) => (
        <ValueWithCurrency
          value={item.pkgCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    {
      title: t("tableColumn.dm"),
      key: "p_dm_column",
      align: "center",
      width: 80,
      render: (item: PackagingSkuDataItem) => (
        <ValueWithCurrency value={item.dm} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.dl"),
      key: "p_dl_column",
      align: "center",
      width: 80,
      render: (item: PackagingSkuDataItem) => (
        <ValueWithCurrency value={item.dl} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.fov"),
      key: "p_fov_column",
      align: "center",
      width: 80,
      render: (item: PackagingSkuDataItem) => (
        <ValueWithCurrency value={item.fov} currencyCode={item.currencyCode} />
      ),
    },
    {
      title: t("tableColumn.fof1"),
      key: "p_fof1_column",
      align: "center",
      width: 80,
      render: (item: PackagingSkuDataItem) => (
        <ValueWithCurrency value={item.fof1} currencyCode={item.currencyCode} />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: PackagingSkuDataItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
    },
    onSelect: async (item: PackagingSkuDataItem, selected: boolean) => {
      setTableLoading(true);

      // handle item select/deselect
      await packagingService.selectPackagingSkuDataItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        item.id
      );

      setSelectedValType(item.valType);
      setTableLoading(false);
    },
  };

  return (
    <React.Fragment>
      <Text style={{ display: "block", marginBottom: 15 }}>
        {t("label.selectOneSkuFromTheListBelow")}
      </Text>
      <Table
        key="packaging-sku-selection"
        columns={columns}
        loading={tableLoading || dataRefreshing}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content" }}
        bordered={true}
        rowSelection={{
          ...rowSelection,
          type: "radio",
          selectedRowKeys: selectedRowKeys,
          hideSelectAll: true,
        }}
        summary={(tableData) => {
          let averagePkgCost = 0;
          let averageDmCost = 0;
          let averageDlCost = 0;
          let averageFovCost = 0;
          let averageFof1Cost = 0;

          if (tableData.length > 0) {
            averagePkgCost =
              calculateCostPropertySum(
                tableData as [],
                "pkgCost",
                currentPriceConfigurator.currencies ?? [],
                currentUser.currency?.currencyCode ?? ""
              ) / tableData.length;

            averageDmCost =
              calculateCostPropertySum(
                tableData as [],
                "dm",
                currentPriceConfigurator.currencies ?? [],
                currentUser.currency?.currencyCode ?? ""
              ) / tableData.length;

            averageDlCost =
              calculateCostPropertySum(
                tableData as [],
                "dl",
                currentPriceConfigurator.currencies ?? [],
                currentUser.currency?.currencyCode ?? ""
              ) / tableData.length;

            averageFovCost =
              calculateCostPropertySum(
                tableData as [],
                "fov",
                currentPriceConfigurator.currencies ?? [],
                currentUser.currency?.currencyCode ?? ""
              ) / tableData.length;

            averageFof1Cost =
              calculateCostPropertySum(
                tableData as [],
                "fof1",
                currentPriceConfigurator.currencies ?? [],
                currentUser.currency?.currencyCode ?? ""
              ) / tableData.length;
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={8} index={0}>
                <Text style={{ float: "right", fontWeight: 600 }}>
                  {t("tableFooter.average")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averagePkgCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageDmCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageDlCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageFovCost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5}>
                {dataItems && dataItems.length > 0 && (
                  <ValueWithCurrency
                    value={averageFof1Cost}
                    currencyCode={currentUser.currency?.currencyCode}
                  />
                )}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
