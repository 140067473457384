import { Collapse, Radio, Skeleton, Space, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { SkeletonInputsTable } from "src/core/components/SkeletonInputsTable";
import { useAuth } from "src/core/hooks/useAuth";
import { metaDataService } from "src/core/services/metaDataService";
import { dataSectionConfigurationService } from "src/core/services/priceConfiguratorServices/dataSectionConfigurationService";
import { lensesService } from "src/core/services/priceConfiguratorServices/lensesService";
import {
  CostEvaluationEnum,
  PlasticTypeEnum,
  SectionSettingEnum,
} from "src/enums";
import { SunLensesManualOrderFormTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/SunLensesManager/components/SunLensesManualOrderFormTable";
import { SunLensesOrderFormTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/SunLensesManager/components/SunLensesOrderFormTable/SunLensesOrderFormTable";
import { SunLensesSummaryTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Lenses/components/SunLensesManager/components/SunLensesSummaryTable";
import { dataSectionContextState } from "src/recoil/atoms";
import {
  SunLensesOrderFormDataItem,
  SunLensesSummaryDataItem,
} from "src/types/priceConfigurator";

const { Text } = Typography;
const { Panel } = Collapse;

type Props = {
  priceConfiguratorId: number;
  modelNumber: string;
  dataSectionId: number;
};

export const SunLensesManager = (props: Props) => {
  const { priceConfiguratorId, modelNumber, dataSectionId } = props;

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [costEvaluationOption, setCostEvaluationOption] =
    useState<CostEvaluationEnum>();
  const [selectedPlasticOption, setSelectedPlasticOption] =
    useState<PlasticTypeEnum>();

  const [lensesSunDataItems, setLensesSunDataItems] =
    useState<SunLensesOrderFormDataItem[]>();
  const [lensesSunSummaryDataItems, setLensesSunSummaryDataItems] =
    useState<SunLensesSummaryDataItem[]>();
  const [summaryDataUpdating, setSummaryDataUpdating] = useState(false);
  const [lensesSunDataUpdate, setLensesSunDataUpdate] = useState(false);

  const [costEvaluationUpdate, setCostEvaluationUpdate] = useState(false);
  const [plasticOptionUpdate, setPlasticOptionUpdate] = useState(false);

  const setDataSectionContext = useSetRecoilState(dataSectionContextState);

  const refreshSummaryTable = useCallback(async () => {
    setSummaryDataUpdating(true);

    const sunLensesSummaryData = await lensesService.fetchSunLensesSummaryData(
      accessToken,
      priceConfiguratorId,
      modelNumber
    );

    setLensesSunSummaryDataItems(sunLensesSummaryData);
    setSummaryDataUpdating(false);
  }, [accessToken, modelNumber, priceConfiguratorId]);

  const onRecordAddRemove = useCallback(async () => {
    if (
      costEvaluationOption === CostEvaluationEnum.ORDERFORM &&
      selectedPlasticOption !== undefined
    ) {
      const response = await lensesService.fetchModelLensesSunData(
        accessToken,
        priceConfiguratorId,
        modelNumber,
        selectedPlasticOption
      );

      setLensesSunDataItems(response);
    }
  }, [
    accessToken,
    costEvaluationOption,
    modelNumber,
    priceConfiguratorId,
    selectedPlasticOption,
  ]);

  useEffect(() => {
    (async () => {
      if (costEvaluationOption === undefined) {
        if (authLoaded && success) {
          const response =
            await dataSectionConfigurationService.fetchDataSectionSetting(
              accessToken,
              priceConfiguratorId,
              dataSectionId,
              SectionSettingEnum.COSTEVALUATION
            );

          setCostEvaluationOption(response.costCalculationType);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costEvaluationOption,
    dataSectionId,
    priceConfiguratorId,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (
        costEvaluationOption === CostEvaluationEnum.ORDERFORM &&
        selectedPlasticOption !== undefined
      ) {
        if (authLoaded && success) {
          setLensesSunDataUpdate(true);

          const response = await lensesService.fetchModelLensesSunData(
            accessToken,
            priceConfiguratorId,
            modelNumber,
            selectedPlasticOption
          );

          setLensesSunDataItems(response);

          await refreshSummaryTable();

          setLensesSunDataUpdate(false);
          setLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costEvaluationOption,
    modelNumber,
    priceConfiguratorId,
    refreshSummaryTable,
    selectedPlasticOption,
    setLensesSunDataUpdate,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (selectedPlasticOption === undefined) {
          const response =
            await dataSectionConfigurationService.fetchDataSectionSetting(
              accessToken,
              priceConfiguratorId,
              dataSectionId,
              SectionSettingEnum.PLASTICTYPE
            );

          setSelectedPlasticOption(response.plasticType);

          const lensMaterials =
            await metaDataService.fetchAvailableDataSectionMaterials(
              accessToken,
              priceConfiguratorId,
              dataSectionId
            );

          setDataSectionContext((prevState) => {
            return { ...prevState, lensMaterials: lensMaterials };
          });
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    dataSectionId,
    priceConfiguratorId,
    selectedPlasticOption,
    setDataSectionContext,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setSummaryDataUpdating(true);

        const sunLensesSummaryData =
          await lensesService.fetchSunLensesSummaryData(
            accessToken,
            priceConfiguratorId,
            modelNumber
          );

        setLensesSunSummaryDataItems(sunLensesSummaryData);

        setSummaryDataUpdating(false);
        setLoading(false);
      }
    })();
  }, [accessToken, authLoaded, modelNumber, priceConfiguratorId, success]);

  const deleteSunOrderFormData = async () => {
    await lensesService.deleteSunOrderFormData(
      accessToken,
      priceConfiguratorId,
      modelNumber
    );
  };

  return (
    <Skeleton
      loading={loading}
      active
      style={{ minHeight: 200, minWidth: 200 }}
    >
      <Space
        direction="vertical"
        size={0}
        style={{ marginBottom: 50 }}
        className="full-width-space"
      >
        <Title style={{ marginBottom: 5, display: "block" }} level={5}>
          {t("label.costSummary")}
        </Title>
        {lensesSunSummaryDataItems ? (
          <SunLensesSummaryTable
            data={lensesSunSummaryDataItems}
            dataRefreshing={summaryDataUpdating}
          />
        ) : (
          <SkeletonInputsTable />
        )}
        <Space style={{ marginBottom: 15, marginTop: 30 }}>
          <Text style={{ display: "block", width: 160 }}>
            {t("label.evaluateCostsBasedOn")}:
          </Text>
          <Radio.Group
            disabled={costEvaluationUpdate}
            onChange={async (_ev) => {
              setCostEvaluationUpdate(true);

              await dataSectionConfigurationService.updateDataSectionSetting(
                accessToken,
                priceConfiguratorId,
                dataSectionId,
                SectionSettingEnum.COSTEVALUATION,
                _ev.target.value
              );

              await deleteSunOrderFormData();

              setCostEvaluationOption(_ev.target.value);

              await refreshSummaryTable();

              setCostEvaluationUpdate(false);
            }}
            value={costEvaluationOption}
          >
            <Space direction="vertical">
              <Radio value={CostEvaluationEnum.ORDERFORM}>
                {t("radioOption.orderForm")}
              </Radio>
              <Radio value={CostEvaluationEnum.MANUALINPUT}>
                {t("radioOption.manualInput")}
              </Radio>
            </Space>
          </Radio.Group>
        </Space>
        <Space className="full-width-space">
          <Collapse defaultActiveKey={["order-form", "manual-input"]} ghost>
            {costEvaluationOption === CostEvaluationEnum.ORDERFORM &&
              selectedPlasticOption !== undefined && (
                <Panel
                  header={<Title level={4}>{t("label.orderForm")}</Title>}
                  key="order-form"
                >
                  <Space direction="vertical" className="full-width-space">
                    <Space style={{ marginBottom: 15 }}>
                      <Text style={{ display: "block", width: 160 }}>
                        {t("label.forPlasticUse")}:
                      </Text>
                      <Radio.Group
                        disabled={plasticOptionUpdate}
                        onChange={async (_ev) => {
                          setPlasticOptionUpdate(true);

                          await dataSectionConfigurationService.updateDataSectionSetting(
                            accessToken,
                            priceConfiguratorId,
                            dataSectionId,
                            SectionSettingEnum.PLASTICTYPE,
                            _ev.target.value
                          );

                          await deleteSunOrderFormData();

                          setSelectedPlasticOption(_ev.target.value);

                          setPlasticOptionUpdate(false);

                          const lensMaterials =
                            await metaDataService.fetchAvailableDataSectionMaterials(
                              accessToken,
                              priceConfiguratorId,
                              dataSectionId
                            );

                          setDataSectionContext((prevState) => {
                            return {
                              ...prevState,
                              lensMaterials: lensMaterials,
                            };
                          });
                        }}
                        value={selectedPlasticOption}
                      >
                        <Space direction="vertical">
                          <Radio value={PlasticTypeEnum.PLASTICLENS}>
                            {t("radioOption.plasticLens")}
                          </Radio>
                          <Radio value={PlasticTypeEnum.PLASTICMASK}>
                            {t("radioOption.plasticMask")}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Space>
                    <Space direction="vertical" className="full-width-space">
                      <Text style={{ display: "block" }}>
                        {t("message.selectDesiredLenses")}:
                      </Text>
                      {lensesSunDataItems && (
                        <SunLensesOrderFormTable
                          selectedPlasticType={selectedPlasticOption}
                          dataSectionId={dataSectionId}
                          data={lensesSunDataItems}
                          dataRefreshing={lensesSunDataUpdate}
                          onDataChange={refreshSummaryTable}
                          onRecordAddRemove={onRecordAddRemove}
                        />
                      )}
                    </Space>
                  </Space>
                </Panel>
              )}
            {costEvaluationOption === CostEvaluationEnum.MANUALINPUT && (
              <Panel
                header={<Title level={4}>{t("label.manualInput")}</Title>}
                key="manual-input"
              >
                <Space direction="vertical" className="full-width-space">
                  <Text style={{ display: "block" }}>
                    {t("message.enterLensesProperties")}:
                  </Text>
                  <SunLensesManualOrderFormTable
                    onDataChange={refreshSummaryTable}
                    dataSectionId={dataSectionId}
                  />
                </Space>
              </Panel>
            )}
          </Collapse>
        </Space>
      </Space>
    </Skeleton>
  );
};
