import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Skeleton,
  Space,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Title from "antd/es/typography/Title";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { mergeService } from "src/core/services/priceConfiguratorServices/mergeService";
import { MergeModelBlock } from "src/modules/PriceConfiguratorMergeDrawer/components/MergeModelBlock";
import {
  IModelMergeData,
  model1MergeState,
  model2MergeState,
} from "src/modules/PriceConfiguratorMergeDrawer/recoil/atoms";

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (value: boolean) => void;
  onClose?: () => void;
  onSuccess?: () => Promise<void>;
};

const { Text } = Typography;

export const PriceConfiguratorMergeDrawer: React.FC<Props> = (props: Props) => {
  const { openDrawer, setOpenDrawer, onClose, onSuccess } = props;

  const [mergeAsReference, setMergeAsReference] = useState(true);
  const [merging, setMerging] = useState(false);
  const [loading, setLoading] = useState(true);

  const { accessToken, success, authLoaded } = useAuth();

  const { t } = useTranslation();

  const [model1MergeStateData, setModel1MergeStateData] =
    useRecoilState(model1MergeState);
  const [model2MergeStateData, setModel2MergeStateData] =
    useRecoilState(model2MergeState);

  const model1MergeStateDataRef = useRef<IModelMergeData>(model1MergeStateData);
  const model2MergeStateDataRef = useRef<IModelMergeData>(model2MergeStateData);

  useEffect(() => {
    if (authLoaded && success) {
      setLoading(false);
    }
  }, [authLoaded, success]);

  useEffect(() => {
    if (
      (model1MergeStateDataRef.current.priceConfiguratorId === undefined &&
        model1MergeStateData.priceConfiguratorId) ||
      (model2MergeStateDataRef.current.priceConfiguratorId === undefined &&
        model2MergeStateData.priceConfiguratorId)
    ) {
      setOpenDrawer(true);
    }
  }, [
    model1MergeStateData.priceConfiguratorId,
    model2MergeStateData.priceConfiguratorId,
    setOpenDrawer,
  ]);

  const onCloseDrawer = () => {
    setOpenDrawer(false);

    if (onClose) {
      onClose();
    }
  };

  const submitMerge = async () => {
    await mergeService.mergePriceConfigurators(accessToken, {
      isReference: mergeAsReference,
      mergePriceConfigurators: [
        {
          sourcePriceConfiguratorId: model1MergeStateData.priceConfiguratorId,
          weight: model1MergeStateData.weight,
          notes: model1MergeStateData.notes,
        },
        {
          sourcePriceConfiguratorId: model2MergeStateData.priceConfiguratorId,
          weight: model2MergeStateData.weight,
          notes: model2MergeStateData.notes,
        },
      ],
    });
  };

  return (
    <Drawer
      placement="right"
      onClose={onCloseDrawer}
      open={openDrawer}
      styles={{
        header: { padding: "3px 8px 3px 8px" },
        body: { paddingTop: 5 },
      }}
    >
      <Skeleton loading={loading} style={{ padding: 10 }} active>
        <Space direction="vertical" className="full-width-space" size={15}>
          <Title
            level={5}
            style={{
              fontSize: 14,
              marginTop: 15,
              marginBottom: 20,
              textAlign: "center",
            }}
          >
            {t("title.priceConfiguratorMerge")}
          </Title>
          <Space direction="vertical" className="full-width-space" size={25}>
            <MergeModelBlock
              data={model1MergeStateData}
              setData={setModel1MergeStateData}
              title={t("label.model1")}
            />
            <MergeModelBlock
              data={model2MergeStateData}
              setData={setModel2MergeStateData}
              title={t("label.model2")}
            />
          </Space>
          <Checkbox
            checked={mergeAsReference}
            onChange={(_ev: CheckboxChangeEvent) => {
              setMergeAsReference(_ev.target.checked);
            }}
          >
            <Text style={{ fontWeight: 600 }}>
              {t("checkBox.referencePaca")}
            </Text>
          </Checkbox>
          <Flex justify="center">
            <Button
              loading={merging}
              disabled={
                !model1MergeStateData?.priceConfiguratorId ||
                !model2MergeStateData?.priceConfiguratorId ||
                model1MergeStateData?.isError ||
                model2MergeStateData?.isError ||
                merging
              }
              onClick={async () => {
                setMerging(true);

                await submitMerge();

                await onSuccess?.();

                setModel1MergeStateData({});
                setModel2MergeStateData({});
                setMerging(false);
              }}
            >
              {t("label.mergeModels")}
            </Button>
          </Flex>
        </Space>
      </Skeleton>
    </Drawer>
  );
};
