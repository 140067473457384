import { InteractionStatus } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import { AdministrationPage } from "src/pages/AdministrationPage/AdministrationPage";
import { CostComparisonPage } from "src/pages/CostComparison/CostComparisonPage";
import { CostExplosionPage } from "src/pages/CostExplosion/CostExplosionPage";
import { Home } from "src/pages/Home/Home";
import { NoAccess } from "src/pages/NoAccess/NoAccess";
import { DataSectionPage } from "src/pages/PriceConfigurator/DataSectionPage";
import { MasterInfoPage } from "src/pages/PriceConfigurator/MasterInfoPage";
import { PriceConfiguratorPage } from "src/pages/PriceConfigurator/PriceConfiguratorPage";
import { SignIn } from "src/pages/SignIn/SignIn";
import "./App.css";
import { loginRedirectRequest } from "./authConfig";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          path="/price-configurator"
          element={
            <RequireAuth>
              <PriceConfiguratorPage />
            </RequireAuth>
          }
        />
        <Route
          path="/price-configurator/list"
          element={
            <RequireAuth>
              <PriceConfiguratorPage />
            </RequireAuth>
          }
        />
        <Route
          path="/price-configurator/manager/master-info"
          element={
            <RequireAuth>
              <MasterInfoPage />
            </RequireAuth>
          }
        />
        <Route
          path="/price-configurator/manager/master-info/new"
          element={
            <RequireAuth>
              <MasterInfoPage createNewMode={true} />
            </RequireAuth>
          }
        />
        <Route
          path="/price-configurator/manager/section/:sectionEnum/:sectionIndex"
          element={
            <RequireAuth>
              <DataSectionPage />
            </RequireAuth>
          }
        />
        <Route
          path="/cost-explosion"
          element={
            <RequireAuth>
              <CostExplosionPage />
            </RequireAuth>
          }
        />
        <Route
          path="/cost-comparison"
          element={
            <RequireAuth>
              <CostComparisonPage />
            </RequireAuth>
          }
        />
        <Route
          path="/administration"
          element={
            <RequireAuth>
              <AdministrationPage />
            </RequireAuth>
          }
        />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/no-access" element={<NoAccess />} />
      </Routes>
    </Router>
  );
}

// helper to define auth required routes
function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRedirectRequest);
    return <UnauthenticatedTemplate>Loading...</UnauthenticatedTemplate>;
  } else {
    return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
  }
}

export default App;
