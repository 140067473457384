import { Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { DataSectionType } from "src/enums";
import { DataSectionManager } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/DataSectionManager";
import { DataSectionTabsManager } from "src/modules/PriceConfiguratorManager/components/DataSectionTabsManager/DataSectionTabsManager";
import {
  DataSectionData,
  DataSectionRenderData,
} from "src/types/priceConfigurator";

type Props = {
  dataSectionType: DataSectionType;
  dataSection: DataSectionData;
};

const dataSectionRenderData: DataSectionRenderData[] = [
  {
    sectionType: DataSectionType.FRAMEALIASES,
    hasTabs: true,
  },
];

export const DataSection = (props: Props) => {
  const { dataSectionType, dataSection } = props;

  const [loading, setLoading] = useState(true);
  const [renderData, setRenderData] = useState<DataSectionRenderData>();

  useEffect(() => {
    (async () => {
      const sectionRenderData = dataSectionRenderData.find(
        (data: DataSectionRenderData) => data.sectionType === dataSectionType
      );

      setRenderData(sectionRenderData);
      setLoading(false);
    })();
  }, [dataSectionType]);

  return (
    <Skeleton loading={loading}>
      <Space
        direction="vertical"
        align="start"
        size={"large"}
        style={{ width: "100%" }}
        className="full-width-space"
        wrap
      >
        {renderData?.hasTabs ? (
          <DataSectionTabsManager dataSection={dataSection} />
        ) : (
          <DataSectionManager dataSection={dataSection} />
        )}
      </Space>
    </Skeleton>
  );
};
