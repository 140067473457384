import { Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { TableFooter } from "src/core/components/TableFooter";
import { useAuth } from "src/core/hooks/useAuth";
import { aliasMasterDataService } from "src/core/services/priceConfiguratorServices/aliasMasterDataService";
import { frameAliasesService } from "src/core/services/priceConfiguratorServices/frameAliasesService";
import { DataPropertyType } from "src/enums";
import {
  FrameAliasesContext,
  FrameAliasesContextType,
} from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/context/FrameAliasesContext";
import { useRouteDataTableColumns } from "src/modules/PriceConfiguratorManager/tables/RouteDataTable/hooks/useRouteDataTableColumns";
import { TableColumnsSelector } from "src/modules/TableColumnsSelector/TableColumnsSelector";
import { appParamsState } from "src/recoil/atoms";
import { DataSectionData, RouteDataItem } from "src/types/priceConfigurator";

type Props = {
  skuValue: string;
  aliasId: number;
  priceConfiguratorId: number;
  dataSection: DataSectionData;
  loading: boolean;
};

export const RouteDataTable: React.FC<Props> = (props: Props) => {
  const { loading, aliasId, priceConfiguratorId, dataSection, skuValue } =
    props;

  const [tableLoading, setTableLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<RouteDataItem[]>([]);

  const { t } = useTranslation();

  const { accessToken, success, authLoaded } = useAuth();

  const appParams = useRecoilValue(appParamsState);
  const [dataItems, setdataItems] = useState<RouteDataItem[]>([]);

  const { getTableColumns } = useRouteDataTableColumns(dataItems);

  const [tableColumns, setTableColumns] = useState<ColumnsType<RouteDataItem>>(
    []
  );
  const [filteredTableColumns, setFilteredTableColumns] = useState<
    ColumnsType<RouteDataItem>
  >([]);

  const { setRouteLoading } = React.useContext(
    FrameAliasesContext
  ) as FrameAliasesContextType;

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setTableColumns(getTableColumns());
        setFilteredTableColumns(getTableColumns());

        setTableLoading(false);
      }
    })();
  }, [authLoaded, getTableColumns, success]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (skuValue) {
          setRouteLoading(true);
          setTableLoading(true);

          const response = await aliasMasterDataService.fetchRouteDataItems(
            accessToken,
            skuValue,
            priceConfiguratorId,
            dataSection.id,
            aliasId
          );

          const preselectedItems: RouteDataItem[] = response.filter(
            (x: RouteDataItem) => x.isSelected
          );

          const preselectedItemsKeys: number[] = preselectedItems.map(
            (y: RouteDataItem) => {
              return y.id;
            }
          );

          setSelectedRows(preselectedItems);
          setSelectedRowKeys(preselectedItemsKeys);
          setdataItems(response);

          setTableLoading(false);
          setRouteLoading(false);
        } else {
          setdataItems([]);
        }
      }
    })();
  }, [
    accessToken,
    aliasId,
    authLoaded,
    dataSection.id,
    priceConfiguratorId,
    setRouteLoading,
    skuValue,
    success,
  ]);

  const rowSelection = {
    onChange: (selectedRowKey: React.Key[], selectedRows: RouteDataItem[]) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (item: RouteDataItem, selected: boolean) => {
      setTableLoading(true);

      await aliasMasterDataService.updateRouteDataItemSelection(
        accessToken,
        skuValue,
        item.id,
        {
          aliasId: aliasId,
          priceConfiguratorId: priceConfiguratorId,
          priceConfiguratorSectionId: dataSection.id,
        },
        selected
      );

      setTableLoading(false);
    },
    onSelectAll: async (selected: boolean) => {
      setTableLoading(true);

      await aliasMasterDataService.updateRouteDataItemsSelection(
        accessToken,
        skuValue,
        {
          aliasId: aliasId,
          priceConfiguratorId: priceConfiguratorId,
          priceConfiguratorSectionId: dataSection.id,
        },
        selected
      );

      setTableLoading(false);
    },
    onSelectMultiple: async (
      selected: boolean,
      selectedRows: RouteDataItem[],
      changeRows: RouteDataItem[]
    ) => {
      setTableLoading(true);

      await frameAliasesService.updateRouteDataItemsMultiSelection(
        accessToken,
        priceConfiguratorId,
        aliasId,
        dataSection.id,
        skuValue,
        changeRows,
        selected
      );

      setTableLoading(false);
    },
  };

  return (
    <Space direction="vertical">
      <TableColumnsSelector
        baseColumns={tableColumns as []}
        setTableColumns={(columnsList) => {
          setFilteredTableColumns(columnsList as ColumnsType<RouteDataItem>);
        }}
        unselectedColumnKeys={["route-mainLayer", "route-singleLayer"]}
      />
      <Table
        key="route-data-table"
        className="no-selection-background-table small-font-table"
        sticky={true}
        bordered={true}
        columns={[...filteredTableColumns, ...[Table.SELECTION_COLUMN]]}
        loading={loading || tableLoading}
        dataSource={dataItems}
        pagination={false}
        rowKey={({ id }) => id}
        scroll={{ x: "max-content", y: 640 }}
        style={{ width: appParams.contentWidth - 60 }}
        rowSelection={{
          ...rowSelection,
          type: "checkbox",
          selectedRowKeys: selectedRowKeys,
        }}
        size={"small"}
        footer={() => {
          return (
            <TableFooter
              dataItems={selectedRows}
              properties={[
                {
                  label: t("tableColumn.costDl"),
                  type: DataPropertyType.COST,
                  property: "costDl",
                },
                {
                  label: t("tableColumn.costFov"),
                  type: DataPropertyType.COST,
                  property: "costFov",
                },
                {
                  label: t("tableColumn.costFof1"),
                  type: DataPropertyType.COST,
                  property: "costFof",
                },
                {
                  label: t("tableColumn.totalCost"),
                  type: DataPropertyType.COST,
                  property: "totalCost",
                },
              ]}
            />
          );
        }}
      />
    </Space>
  );
};
