import {
  ArrowsAltOutlined,
  ControlOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  EuroCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { getSidebarItem } from "src/core/utils/sidebar";
import { AppFeatureEnum } from "src/enums";
import { AppPage } from "src/modules/AdminSidebar/AdminSidebar";
import { appFeaturesState } from "src/recoil/atoms";

export function useAdminSidebarItems(
  pageToAddChilds?: AppPage,
  childSidebarItems?: SidebarItem[]
) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const appFeatures = useRecoilValue(appFeaturesState);

  const sidebarItems = useMemo(() => {
    const getCurrentPageChildSidebarItems = (
      currenPage: AppPage
    ): SidebarItem[] | undefined => {
      if (pageToAddChilds && currenPage === pageToAddChilds) {
        if (childSidebarItems && childSidebarItems.length > 0) {
          return childSidebarItems;
        }
      }

      return undefined;
    };

    const getSidebarItemByFeatureEnum = (
      feature: AppFeatureEnum
    ): SidebarItem | undefined => {
      switch (feature) {
        case AppFeatureEnum.REPOSITORYFEATURE: {
          return getSidebarItem(
            t("sidebar.priceConfigurators"),
            "price-configurator/list",
            <DatabaseOutlined />,
            () => {
              navigate("/price-configurator/list");
            }
          );
        }
        case AppFeatureEnum.PRICECONFIGURATORFEATURE: {
          return getSidebarItem(
            t("sidebar.priceConfigurator"),
            "price-configurator",
            <DesktopOutlined />,
            () => {
              if (
                (getCurrentPageChildSidebarItems("price-configurator")
                  ?.length ?? 0) === 0
              ) {
                navigate("/price-configurator/manager/master-info/new");
              }
            },
            getCurrentPageChildSidebarItems("price-configurator")
          );
        }
        case AppFeatureEnum.COSTEXPLOSIONFEATURE: {
          return getSidebarItem(
            t("sidebar.costExplosion"),
            "cost-explosion",
            <EuroCircleOutlined />,
            () => {
              navigate("/cost-explosion");
            }
          );
        }
        case AppFeatureEnum.COSTCOMPARISONFEATURE: {
          return getSidebarItem(
            t("sidebar.costComparison"),
            "cost-comparison",
            <ArrowsAltOutlined />,
            () => {
              navigate("/cost-comparison");
            }
          );
        }
        case AppFeatureEnum.ADMINISTRATIONFEATURE: {
          return getSidebarItem(
            t("sidebar.administration"),
            "administration",
            <ControlOutlined />,
            () => {
              navigate("/administration");
            }
          );
        }
        default: {
          return undefined;
        }
      }
    };

    let result: SidebarItem[] = [
      getSidebarItem(t("sidebar.home"), "home", <UserOutlined />, () =>
        navigate("/")
      ),
    ];

    appFeatures.forEach((feature) => {
      const item = getSidebarItemByFeatureEnum(feature);

      if (item) {
        result.push(item);
      }
    });
    return result;
  }, [appFeatures, childSidebarItems, navigate, pageToAddChilds, t]);

  return {
    sidebarItems,
  };
}
