import { Collapse, Divider, Skeleton, Space } from "antd";
import Title from "antd/es/typography/Title";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { madeInOriginService } from "src/core/services/priceConfiguratorServices/madeInOriginService";
import { MadeInOriginSectionTypeEnum } from "src/enums";
import { CalculationTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/components/CalculationTable";
import { HeaderTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/components/HeaderTable";
import { RateAdjustmentsTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/components/RateAdjustmentsTable";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import {
  DataSectionData,
  MadeInOriginCalculationStatsItem,
  MadeInOriginStatsItem,
} from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
  title: string;
  type: MadeInOriginSectionTypeEnum;
};

const { Panel } = Collapse;

export const RateAdjustmentsManager = (props: Props) => {
  const { title, type } = props;

  const { success, authLoaded, accessToken } = useAuth();
  const { t } = useTranslation();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);

  const [refreshingStats, setRefreshingStats] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [headerStats, setHeaderStats] = useState<MadeInOriginStatsItem[]>([]);
  const [calculationStats, setCalculationStats] = useState<
    MadeInOriginCalculationStatsItem[]
  >([]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (dataLoaded) {
          const calculationStats =
            await madeInOriginService.fetchMadeInOriginCalculationStats(
              accessToken,
              currentPriceConfigurator.id,
              currentPriceConfigurator.modelNumber,
              type
            );

          const overallStats =
            await madeInOriginService.fetchMadeInOriginOverallStats(
              accessToken,
              currentPriceConfigurator.id,
              currentPriceConfigurator.modelNumber,
              type
            );

          setHeaderStats(overallStats);
          setCalculationStats(calculationStats);

          setLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    dataLoaded,
    success,
    type,
  ]);

  const refreshStats = useCallback(async () => {
    setRefreshingStats(true);

    const calculationStats =
      await madeInOriginService.fetchMadeInOriginCalculationStats(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        type
      );

    const overallStats =
      await madeInOriginService.fetchMadeInOriginOverallStats(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        type
      );

    setHeaderStats(overallStats);
    setCalculationStats(calculationStats);
    setRefreshingStats(false);
  }, [
    accessToken,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    type,
  ]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50, maxWidth: 1280 }}
      className="full-width-space"
    >
      <Skeleton
        loading={false}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <Divider orientation="left" plain style={{ border: "gray" }}>
          <Title level={4}>{title}</Title>
        </Divider>
        <HeaderTable
          dataItems={headerStats}
          dataRefreshing={loading || refreshingStats}
        />
        <Collapse defaultActiveKey={["panel-1", "panel-2"]} ghost>
          <Panel
            header={
              <Title level={5}>{t("label.exchangeRateAdjustments")}</Title>
            }
            key="panel-1"
          >
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
              <RateAdjustmentsTable
                onLoad={async () => {
                  setDataLoaded(true);
                }}
                onDataChange={async () => {
                  await refreshStats();
                }}
                type={type}
              />
            </Space>
          </Panel>
          <Panel
            header={<Title level={5}>{t("label.calculation")}</Title>}
            key="panel-2"
          >
            <Space direction="vertical" size={20} className="full-width-space">
              <CalculationTable
                dataItems={calculationStats}
                dataRefreshing={loading || refreshingStats}
              />
            </Space>
          </Panel>
        </Collapse>
      </Skeleton>
    </Space>
  );
};
