import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  mergeFilterState,
  model1MergeState,
  model2MergeState,
} from "src/modules/PriceConfiguratorMergeDrawer/recoil/atoms";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

export function useMergeModelsState() {
  const [itemsSelected, setItemsSelected] = useState<boolean>(false);

  const [model1MergeStateData, setModel1MergeStateData] =
    useRecoilState(model1MergeState);
  const [model2MergeStateData, setModel2MergeStateData] =
    useRecoilState(model2MergeState);

  const [mergeFilterData, setMergeFilterData] =
    useRecoilState(mergeFilterState);

  useEffect(() => {
    if (
      (model1MergeStateData?.priceConfiguratorId ?? 0) > 0 &&
      (model2MergeStateData?.priceConfiguratorId ?? 0) > 0
    ) {
      setItemsSelected(true);
    } else {
      setItemsSelected(false);
    }
  }, [
    model1MergeStateData?.priceConfiguratorId,
    model2MergeStateData?.priceConfiguratorId,
  ]);

  useEffect(() => {
    if (
      (model1MergeStateData?.priceConfiguratorId ?? 0) === 0 &&
      (model2MergeStateData?.priceConfiguratorId ?? 0) === 0
    ) {
      setMergeFilterData(undefined);
    }
  }, [
    model1MergeStateData?.priceConfiguratorId,
    model2MergeStateData?.priceConfiguratorId,
    setMergeFilterData,
  ]);

  const selectItem = (data: PriceConfiguratorData) => {
    if ((model1MergeStateData?.priceConfiguratorId ?? 0) === 0) {
      setModel1MergeStateData({
        data: data,
        priceConfiguratorId: data.id,
        weight: 1,
      });
    } else {
      if ((model2MergeStateData?.priceConfiguratorId ?? 0) === 0) {
        setModel2MergeStateData({
          data: data,
          priceConfiguratorId: data.id,
          weight: 1,
        });
      }
    }

    setMergeFilterData({
      modelNumber: data.modelNumber,
      prototypeNumber: data.prototypeNumber,
      madeInId: data.madeIn?.id,
      pacaLevelId: data.pacaLevel?.id,
      standardYear: data.standardYear,
      modelTypeId: data.modelType?.id,
      materialId: data.material?.id,
      releaseId: data.release?.id,
    });
  };

  const getItemsCount = () => {
    const count =
      ((model1MergeStateData?.priceConfiguratorId ?? 0) > 0 ? 1 : 0) +
      ((model2MergeStateData?.priceConfiguratorId ?? 0) > 0 ? 1 : 0);

    return count;
  };

  const clearMergeState = useCallback(() => {
    setModel1MergeStateData({});
    setModel2MergeStateData({});
  }, [setModel1MergeStateData, setModel2MergeStateData]);

  return {
    itemsSelected,
    selectItem,
    itemsCount: getItemsCount(),
    priceConfiguratorId1: model1MergeStateData.priceConfiguratorId,
    priceConfiguratorId2: model2MergeStateData.priceConfiguratorId,
    recordsFilter: mergeFilterData,
    clearMergeState,
  };
}
