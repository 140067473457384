import React from "react";
import { useRecoilValue } from "recoil";
import { formatNumber } from "src/core/utils/format";
import {
  currentPriceConfiguratorState,
  currentUserState,
  defaultAppDataState,
} from "src/recoil/atoms";
import { CurrencyData } from "src/types/priceConfigurator";

type Props = {
  value?: number;
  currencyCode?: string;
  currencies?: CurrencyData[];
  precision?: 0 | 1 | 2 | 3 | 4 | 5;
  showZeroIfNull?: boolean;
  showNotAvailableIfNull?: boolean;
  showSymbolIfNull?: string;
  showForceZeroIfZero?: boolean;
  showEmptyIfNullOrZero?: boolean;
  showCurrency?: boolean;
};

export const baseCurrencyCode = "EUR";

export const ValueWithCurrency: React.FC<Props> = (props: Props) => {
  const {
    value,
    precision,
    showZeroIfNull,
    currencyCode,
    currencies,
    showNotAvailableIfNull,
    showSymbolIfNull,
    showForceZeroIfZero,
    showEmptyIfNullOrZero,
    showCurrency,
  } = props;

  const currentUser = useRecoilValue(currentUserState);
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const defaultAppData = useRecoilValue(defaultAppDataState);

  const getValue = () => {
    let processedValue = value;

    let currenciesData = currencies
      ? currencies
      : currentPriceConfigurator.currencies;

    if (currenciesData === undefined) {
      currenciesData = defaultAppData.activeCurrencies;
    }

    if (showSymbolIfNull && (value === null || value === undefined)) {
      return showSymbolIfNull;
    }

    if (
      showEmptyIfNullOrZero &&
      (value === null || value === undefined || value === 0)
    ) {
      return "";
    }

    if (showNotAvailableIfNull && (value === null || value === undefined)) {
      return "N/A";
    }

    if (showZeroIfNull && (value === null || value === undefined)) {
      return formatNumber(0, precision, showZeroIfNull);
    }

    if (showForceZeroIfZero && value === 0) {
      return formatNumber(0, precision, showZeroIfNull);
    }

    if (currencyCode && currenciesData && currenciesData.length > 0) {
      if (processedValue) {
        if (
          currentUser.currency &&
          currentUser.currency?.currencyCode !== currencyCode
        ) {
          const userCurrencyData = currenciesData.find(
            (currency: CurrencyData) =>
              currency.currencyCode === currentUser.currency?.currencyCode
          );

          if (userCurrencyData) {
            // need to convert from the base currency
            if (currencyCode === baseCurrencyCode) {
              processedValue = processedValue * userCurrencyData.rate;
            } else {
              // firstly converting to the base currency and then to the user currency
              const existingCurrencyData = currenciesData.find(
                (currency: CurrencyData) =>
                  currency.currencyCode === currencyCode
              );

              if (existingCurrencyData) {
                processedValue =
                  (processedValue / existingCurrencyData.rate) *
                  userCurrencyData.rate;
              }
            }
          }
        }
      }
    } else {
      return "##.##";
    }

    return formatNumber(processedValue, precision, showZeroIfNull);
  };

  return (
    <React.Fragment>
      {getValue()}
      {showCurrency && getValue() ? (
        <span style={{ marginLeft: 3 }}>
          {currentUser.currency?.currencyCode}
        </span>
      ) : undefined}
    </React.Fragment>
  );
};
