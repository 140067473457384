import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Collapse,
  Input,
  Radio,
  Skeleton,
  Space,
  Tooltip,
  Typography
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { dataSectionConfigurationService } from "src/core/services/priceConfiguratorServices/dataSectionConfigurationService";
import { packagingService } from "src/core/services/priceConfiguratorServices/packagingService";
import { validateInput } from "src/core/utils/input";
import { CostEvaluationEnum, SectionSettingEnum } from "src/enums";
import PackagingCodeSelector from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingCodeSelector";
import { PackagingComponentOperationTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingComponentOperationTable/PackagingComponentOperationTable";
import { PackagingManualOrderFormTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingManualOrderFormTable";
import { PackagingOrderFormTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingOrderFormTable";
import { PackagingSkuSelectionTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingSkuSelectionTable";
import { PackagingSummaryTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/Packaging/components/PackagingManager/components/PackagingSummaryTable";
import {
  PackagingOrderFormCode,
  PackagingSummaryDataItem,
} from "src/types/priceConfigurator";

const { Text } = Typography;

type Props = {
  priceConfiguratorId: number;
  modelNumber: string;
  dataSectionId: number;
};

export const PackagingManager = (props: Props) => {
  const { priceConfiguratorId, modelNumber, dataSectionId } = props;

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [costEvaluationOption, setCostEvaluationOption] =
    useState<CostEvaluationEnum>();

  const [summaryDataItems, setSummaryDataItems] = useState<
    PackagingSummaryDataItem[]
  >([]);
  const [summaryDataUpdating, setSummaryDataUpdating] = useState(false);

  const [costEvaluationUpdate, setCostEvaluationUpdate] = useState(false);

  const [selectedPackagingCode, setSelectedPackagingCode] =
    useState<PackagingOrderFormCode>();

  const [manualAliasValue, setManualAliasValue] = useState<string>();
  const [selectedValType, setSelectedValType] = useState<string>();
  const [code, setCode] = useState<string>();

  useEffect(() => {
    (async () => {
      if (costEvaluationOption === undefined) {
        if (authLoaded && success) {
          const response =
            await dataSectionConfigurationService.fetchDataSectionSetting(
              accessToken,
              priceConfiguratorId,
              dataSectionId,
              SectionSettingEnum.COSTEVALUATION
            );

          setCostEvaluationOption(response.costCalculationType);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    costEvaluationOption,
    dataSectionId,
    priceConfiguratorId,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (costEvaluationOption === CostEvaluationEnum.ORDERFORM) {
        const response = await packagingService.fetchPackagingOrderFormCodes(
          accessToken,
          priceConfiguratorId
        );
        setSelectedPackagingCode(response);
      }
    })();
  }, [
    accessToken,
    costEvaluationOption,
    modelNumber,
    priceConfiguratorId,
    selectedPackagingCode?.code,
    code,
  ]);

  useEffect(() => {
    (async () => {
      if (
        code &&
        selectedPackagingCode !== undefined &&
        validateInput(code, true)
      ) {
        setSummaryDataItems([]);
        setManualAliasValue(undefined);
        setSelectedValType(undefined);

        await packagingService.updatePackagingCodeSelection(
          accessToken,
          priceConfiguratorId,
          code
        );
      }
    })();
  }, [
    accessToken,
    priceConfiguratorId,
    selectedPackagingCode,
    selectedPackagingCode?.code,
    code,
  ]);

  useEffect(() => {
    (async () => {
      if (costEvaluationOption === CostEvaluationEnum.ALIAS) {
        const response = await packagingService.fetchPackagingModelAlias(
          accessToken,
          priceConfiguratorId,
          modelNumber
        );

        setManualAliasValue(response.value);
      }
    })();
  }, [accessToken, costEvaluationOption, modelNumber, priceConfiguratorId]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setSummaryDataUpdating(true);

        const response = await packagingService.fetchPackagingSummaryDataItems(
          accessToken,
          priceConfiguratorId,
          modelNumber
        );

        setSummaryDataItems(response);

        setSummaryDataUpdating(false);
        setLoading(false);
      }
    })();
  }, [accessToken, authLoaded, modelNumber, priceConfiguratorId, success]);

  /**
   * Asynchronously handles updates to a packaging model alias.
   * @param {string | undefined} value - The updated alias value.
   * @returns {Promise<void>}
   */
  const handlePackagingModelAliasUpdate = async (
    value: string | undefined
  ): Promise<void> => {
    setManualAliasValue(value);

    if (costEvaluationOption === CostEvaluationEnum.ALIAS) {
      if (value && validateInput(value, true)) {
        await packagingService.updatePackagingModelAlias(
          accessToken,
          priceConfiguratorId,
          value
        );
      }
    }
  };

  const refreshSummaryTable = useCallback(async () => {
    setSummaryDataUpdating(true);

    const response = await packagingService.fetchPackagingSummaryDataItems(
      accessToken,
      priceConfiguratorId,
      modelNumber
    );

    setSummaryDataItems(response);

    setSummaryDataUpdating(false);
  }, [accessToken, modelNumber, priceConfiguratorId]);

  let contentItems: any[] = [];

  if (costEvaluationOption === CostEvaluationEnum.ORDERFORM) {
    contentItems.push({
      label: <Title level={4}>{t("label.modelSelection")}</Title>,
      key: "model-selection",
      children: (
        <PackagingOrderFormTable
          packagingCode={code || selectedPackagingCode?.code}
          dataRefreshing={false}
          setManualAliasValue={setManualAliasValue}
        />
      ),
    });
  }

  if (
    costEvaluationOption === CostEvaluationEnum.ORDERFORM ||
    costEvaluationOption === CostEvaluationEnum.ALIAS
  ) {
    contentItems.push({
      label: <Title level={4}>{t("label.skuSelection")}</Title>,
      key: "sku-selection",
      children: (
        <PackagingSkuSelectionTable
          modelNumber={manualAliasValue}
          dataRefreshing={false}
          setSelectedValType={setSelectedValType}
        />
      ),
    });

    contentItems.push({
      label: <Title level={4}>{t("label.componentOperationSelection")}</Title>,
      key: "component-operation-selection",
      children: (
        <PackagingComponentOperationTable
          valType={selectedValType}
          dataRefreshing={false}
          onDataChange={refreshSummaryTable}
        />
      ),
    });
  }

  return (
    <Skeleton
      loading={loading}
      active
      style={{ minHeight: 200, minWidth: 240 }}
    >
      <Space
        direction="vertical"
        className="full-width-space"
        size={0}
        style={{ marginBottom: 50 }}
      >
        <Title style={{ marginBottom: 5, display: "block" }} level={5}>
          {t("label.costSummary")}
        </Title>
        <PackagingSummaryTable
          data={summaryDataItems}
          dataRefreshing={summaryDataUpdating}
        />
        <Space style={{ marginBottom: 30, marginTop: 30 }}>
          <Text style={{ display: "block", width: 160 }}>
            {t("label.evaluateCostsBasedOn")}:
          </Text>
          <Radio.Group
            disabled={costEvaluationUpdate}
            onChange={async (_ev) => {
              setCostEvaluationUpdate(true);
              setSummaryDataItems([]);

              await packagingService.deletePackagingData(
                accessToken,
                priceConfiguratorId,
                modelNumber
              );

              setCostEvaluationOption(_ev.target.value);
              setSelectedPackagingCode(undefined);
              setManualAliasValue(undefined);
              setSelectedValType(undefined);
              setCode(undefined);

              await dataSectionConfigurationService.updateDataSectionSetting(
                accessToken,
                priceConfiguratorId,
                dataSectionId,
                SectionSettingEnum.COSTEVALUATION,
                _ev.target.value
              );

              setCostEvaluationUpdate(false);
            }}
            value={costEvaluationOption}
          >
            <Space direction="vertical">
              {costEvaluationOption === undefined ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Radio value={CostEvaluationEnum.ORDERFORM}>
                    {t("radioOption.orderForm")}
                  </Radio>
                  <Radio value={CostEvaluationEnum.ALIAS}>
                    {t("radioOption.alias")}
                  </Radio>
                  <Radio value={CostEvaluationEnum.MANUALINPUT}>
                    {t("radioOption.manualInput")}
                  </Radio>
                </React.Fragment>
              )}
            </Space>
          </Radio.Group>
        </Space>
        <Space className="full-width-space" direction="vertical" size={15}>
          {costEvaluationOption === CostEvaluationEnum.ORDERFORM && (
            <React.Fragment>
              <Title level={4}>{t("label.orderForm")}</Title>
              <Space direction="vertical">
                <Space>
                  <Text style={{ display: "block", width: 120 }}>
                    <b>{t("label.packagingCode")}:</b>
                  </Text>

                  {selectedPackagingCode && (
                    <PackagingCodeSelector
                      initialPackagingCodeValue={selectedPackagingCode.code}
                      packagingCode={selectedPackagingCode}
                      setPackagingCodeValue={setSelectedPackagingCode}
                      setCode={setCode}
                    />
                  )}
                </Space>
                <Space>
                  <Text style={{ display: "block", width: 120 }}>
                    <b>{t("label.collection")}:</b>
                  </Text>
                  <Text>{selectedPackagingCode?.collection}</Text>
                </Space>
              </Space>
            </React.Fragment>
          )}
          {costEvaluationOption === CostEvaluationEnum.ALIAS && (
            <React.Fragment>
              <Title level={4}>{t("label.alias")}</Title>
              <Space>
                <Text style={{ display: "block", width: 80 }}>
                  {t("label.alias")}:
                </Text>
                {manualAliasValue != null || manualAliasValue != undefined ? (
                  <Input
                    style={{ width: 120 }}
                    maxLength={20}
                    defaultValue={manualAliasValue}
                    title={t("label.alias") as string}
                    name={t("label.alias") as string}
                    status={
                      !validateInput(manualAliasValue as string)
                        ? "error"
                        : undefined
                    }
                    suffix={
                      !validateInput(manualAliasValue as string) ? (
                        <Tooltip
                          title={t("message.inputError")}
                          defaultOpen
                          placement="right"
                          overlayStyle={{ fontSize: "12px" }}
                        >
                          <InfoCircleOutlined style={{ color: "red" }} />
                        </Tooltip>
                      ) : (
                        <span />
                      )
                    }
                    onBlur={async (_event) => {
                      await handlePackagingModelAliasUpdate(
                        _event.currentTarget.value
                      );
                    }}
                    onPressEnter={async (_event) => {
                      await handlePackagingModelAliasUpdate(
                        _event.currentTarget.value
                      );
                    }}
                  />
                ) : (
                  <Spinner />
                )}
              </Space>
            </React.Fragment>
          )}
          {costEvaluationOption === CostEvaluationEnum.MANUALINPUT && (
            <Title level={4}>{t("label.manualInput")}</Title>
          )}
          <Collapse
            defaultActiveKey={[
              "model-selection",
              "sku-selection",
              "component-operation-selection",
            ]}
            items={contentItems}
            ghost
          />
          {costEvaluationOption === CostEvaluationEnum.MANUALINPUT && (
            <PackagingManualOrderFormTable onDataChange={refreshSummaryTable} />
          )}
        </Space>
      </Space>
    </Skeleton>
  );
};
