import { Layout, Skeleton, Space, theme, Typography } from "antd";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { useWindowSize } from "src/core/hooks/useWindowSize";
import { AppPage } from "src/modules/AdminSidebar/AdminSidebar";
import { appParamsState } from "src/recoil/atoms";

const { Header, Content } = Layout;
const { Title } = Typography;

type Props = {
  pageTitle: string;
  activePage?: AppPage;
  headerButtons?: JSX.Element[];
  children: JSX.Element | JSX.Element[];
  contentLoading?: boolean;
  pageToAddChilds?: AppPage;
  childSidebarItems?: SidebarItem[];
  centerTitle?: string;
  loading: boolean;
};

export const AdminPageContent: React.FC<Props> = (props: Props) => {
  const { children, pageTitle, headerButtons, contentLoading, loading } = props;

  const elRef = React.useRef<HTMLDivElement>(null);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [appParams, setAppParams] = useRecoilState(appParamsState);
  const { width } = useWindowSize();

  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    if ((elRef?.current?.clientWidth ?? 0) > 0) {
      setAppParams((prev) => {
        return {
          ...prev,
          contentWidth: elRef?.current?.clientWidth ?? 0,
        };
      });
    }
  }, [setAppParams, width]);

  return (
    <Layout
      style={{
        margin: isMobile
          ? "0 16px 0 16px"
          : appParams.sidebarCollapsed
          ? "0 16px 0 96px"
          : "0 16px 0 236px",
        background: colorBgContainer,
      }}
      id="admin-page-content"
      className="admin-page-content"
    >
      <div style={{ width: "100%" }} ref={elRef}>
        <Header
          style={{
            margin: "0 0px",
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Space>
            <Title level={2} style={{ padding: 15, fontSize: 26 }}>
              {pageTitle}
            </Title>
          </Space>
          <Space style={{ float: "right", paddingRight: 15 }}>
            {headerButtons &&
              headerButtons.map((button: JSX.Element) => {
                return button;
              })}
          </Space>
        </Header>
        <Content>
          <Skeleton loading={loading || contentLoading} active={true}>
            <Space direction="vertical" style={{ padding: 15, width: "100%" }}>
              {!contentLoading && children}
            </Space>
          </Skeleton>
        </Content>
      </div>
    </Layout>
  );
};
