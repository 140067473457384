import {
  ExclamationCircleFilled,
  InfoCircleOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  App,
  Button,
  Col,
  Flex,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { InputLabel } from "src/core/components/InputLabel";
import { Spinner } from "src/core/components/Spinner";
import { AppEventTypeEnum } from "src/core/hooks/useAppEvents/enums";
import { appEventsStateData } from "src/core/hooks/useAppEvents/recoil/atoms";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { actionWithConfirm } from "src/core/utils/confirm";
import { validateInput } from "src/core/utils/input";
import { ModelImageViewerP21 } from "src/modules/ModelImageViewer/ModelImageViewerP21";
import { IsReferenceInput } from "src/modules/PriceConfiguratorManager/components/MasterInfo/components/IsReferenceInput";
import { useMasterInfoValidation } from "src/modules/PriceConfiguratorManager/components/MasterInfo/hooks/useMasterInfoValidation";
import {
  currentPriceConfiguratorState,
  masterInfoState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";

const { TextArea } = Input;

type Props = {
  isDataPreserved: boolean;
  setIsDataPreserved: (value: boolean) => void;
  onSaveClick: () => Promise<void>;
  onNextClick: () => Promise<void>;
  onUpdate: (
    propName1: string,
    value1: number | string | boolean,
    propName2?: string | any,
    value2?: boolean
  ) => Promise<void>;
};

export const MasterInfo: React.FunctionComponent<Props> = (props: Props) => {
  const {
    onSaveClick,
    onNextClick,
    onUpdate,
    isDataPreserved,
    setIsDataPreserved,
  } = props;

  const { t } = useTranslation();

  const isMobile = useViewPortQuery() === "mobile";

  const masterInfoData = useRecoilValue(masterInfoState);
  const [currentPriceConfigurator, setCurrentPriceConfigurator] =
    useRecoilState(currentPriceConfiguratorState);

  const { notification } = App.useApp();

  const [loading, setLoading] = useState(true);

  const [inputDataIsValid, setInputDataIsValid] = useState(false);

  const [submitting, setSubmitting] = useState(false);
  const [submittingProp, setSubmittingProp] = useState<string | undefined>();

  const [editMode, setEditMode] = useState(false);
  const [editingUnlocked, setEditingUnlocked] = useState(false);

  const [editedInputs, setEditedInputs] = useState<any>({});
  const [tempModelCode, setTempModelCode] = useState<string>(
    currentPriceConfigurator.modelNumber
  );
  const { dataIsValid } = useMasterInfoValidation();
  const setAppEvents = useSetRecoilState(appEventsStateData);

  const openNotification = (
    description: string,
    notificationStyle: {
      top: number | string;
      paddingRight: number | string;
      color: string;
    }
  ) => {
    notification.open({
      message: "",
      description,
      duration: 0, //in seconds, when set to 0 or null, it will never be closed automatically
      icon: (
        <ExclamationCircleFilled
          style={{
            position: "absolute",
            top: notificationStyle.top,
            color: notificationStyle.color,
          }}
        />
      ),
      style: {
        position: "relative",
        paddingRight: notificationStyle.paddingRight,
      },
    });
  };

  const handleInvalidatePreserve = (event: any) => {
    event.stopPropagation();
    actionWithConfirm(
      t("confirm.masterInfoEditingConfirmTitle"),
      t("confirm.editingMasterInfoConfirmContent"),
      async () => {
        event.stopPropagation();
        actionWithConfirm(
          t("confirm.masterInfoInvalidatePreserveConfirmTitle"),
          t("confirm.masterInfoInvalidatePreserveConfirmContent"),
          async () => {
            setIsDataPreserved(true);

            setAppEvents((prevState) => {
              return [
                ...prevState,
                ...[
                  {
                    priceConfiguratorId: currentPriceConfigurator.id,
                    eventType: AppEventTypeEnum.PACADATAREFRESH,
                  },
                ],
              ];
            });

            setEditingUnlocked(true);
          },
          t("button.preserve"),
          t("button.invalidate"),
          false,
          async () => {
            setEditingUnlocked(true);

            setAppEvents((prevState) => {
              return [
                ...prevState,
                ...[
                  {
                    priceConfiguratorId: currentPriceConfigurator.id,
                    eventType: AppEventTypeEnum.PACADATAREFRESH,
                  },
                ],
              ];
            });
          },
          undefined
        );
      },
      t("button.continue")
    );
  };

  /**
   * Sets edit mode if neccesarry
   */
  useEffect(() => {
    (async () => {
      if (currentPriceConfigurator && currentPriceConfigurator.id) {
        setEditMode(true);
      }

      setLoading(false);
    })();
  }, [currentPriceConfigurator]);

  /**
   * Validates model number before setting it
   */
  useEffect(() => {
    const inputDataValidationResult = validateInput(
      tempModelCode as string,
      false
    );

    setInputDataIsValid(inputDataValidationResult);
  }, [tempModelCode]);

  return (
    <Skeleton loading={loading}>
      <Space direction="vertical" size={"large"}>
        <Space align="start" size={"large"} wrap></Space>
      </Space>
      <Row justify="space-evenly" style={{ maxWidth: 1200 }}>
        <Col lg={8} md={12} sm={12}>
          <Space direction="vertical">
            <Space>
              <InputLabel label={t("input.model")} />
              <Input
                className="disabled-gray-text"
                disabled={editMode && !editingUnlocked}
                maxLength={20}
                title={t("input.model") as string}
                name={t("input.model") as string}
                status={!validateInput(tempModelCode) ? "error" : undefined}
                suffix={
                  !validateInput(tempModelCode) ? (
                    <Tooltip
                      title={t("message.inputError")}
                      defaultOpen
                      placement="right"
                      overlayStyle={{ fontSize: "12px" }}
                    >
                      <InfoCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  ) : (
                    <span />
                  )
                }
                style={{
                  width: 220,
                  background: "transparent",
                  borderColor:
                    editMode && tempModelCode?.trim() === ""
                      ? "red"
                      : undefined,
                }}
                value={tempModelCode}
                onChange={
                  !currentPriceConfigurator.id
                    ? (_event) => {
                        setCurrentPriceConfigurator({
                          ...currentPriceConfigurator,
                          modelNumber: _event.target.value,
                        });
                        setTempModelCode(_event.target.value);
                      }
                    : (_event) => {
                        setTempModelCode(_event.target.value);
                      }
                }
                onBlur={
                  editMode
                    ? async (_event: any) => {
                        if (validateInput(tempModelCode, false)) {
                          actionWithConfirm(
                            t(
                              "confirm.masterInfoEditingModelNumberConfirmTitle"
                            ),
                            t(
                              "confirm.masterInfoEditingModelNumberConfirmContent"
                            ),
                            async () => {
                              setSubmitting(true);

                              await onUpdate(
                                "modelNumber",
                                _event.target.value.trim()
                              );

                              setTempModelCode(_event.target.value.trim());

                              setSubmitting(false);

                              setEditedInputs((prevState: any) => {
                                return { ...prevState, modelNumber: true };
                              });
                            },
                            t("button.continue"),
                            undefined,
                            editedInputs.modelNumber || isDataPreserved,
                            async () => {
                              setTempModelCode(
                                currentPriceConfigurator.modelNumber
                              );
                            }
                          );
                        }
                      }
                    : undefined
                }
              />
            </Space>
            <Space>
              <InputLabel label={t("input.prototype")} />
              <Input
                className="disabled-gray-text"
                disabled={editMode && !editingUnlocked}
                style={{ width: 220 }}
                maxLength={200}
                status={
                  !validateInput(
                    currentPriceConfigurator.prototypeNumber as string
                  )
                    ? "error"
                    : undefined
                }
                title={t("input.prototype") as string}
                name={t("input.prototype") as string}
                suffix={
                  !validateInput(
                    currentPriceConfigurator.prototypeNumber as string
                  ) ? (
                    <Tooltip
                      title={t("message.inputError")}
                      defaultOpen
                      placement="right"
                      overlayStyle={{ fontSize: "12px" }}
                    >
                      <InfoCircleOutlined style={{ color: "red" }} />
                    </Tooltip>
                  ) : (
                    <span />
                  )
                }
                value={currentPriceConfigurator?.prototypeNumber}
                onChange={(_event) => {
                  setCurrentPriceConfigurator({
                    ...currentPriceConfigurator,
                    prototypeNumber: _event.target.value,
                  });
                }}
                onPressEnter={
                  editMode
                    ? async (_event: any) => {
                        if (validateInput(_event.target.value as string)) {
                          setSubmitting(true);

                          await onUpdate(
                            "prototypeNumber",
                            _event.target.value
                          );

                          setSubmitting(false);
                        } else {
                          return;
                        }
                      }
                    : undefined
                }
                onBlur={
                  editMode
                    ? async (_event: any) => {
                        if (validateInput(_event.target.value as string)) {
                          setSubmitting(true);

                          await onUpdate(
                            "prototypeNumber",
                            _event.target.value
                          );

                          setSubmitting(false);
                        } else {
                          return;
                        }
                      }
                    : undefined
                }
              />
            </Space>
            <Space>
              <InputLabel label={t("input.pacaLevel")} />
              <Select
                className="disabled-gray-text"
                loading={submittingProp === "pacaLevelId"}
                disabled={
                  (editMode && !editingUnlocked) ||
                  submittingProp === "pacaLevelId"
                }
                value={
                  currentPriceConfigurator.pacaLevelId ??
                  currentPriceConfigurator.pacaLevel?.id
                }
                fieldNames={{ value: "id", label: "label" }}
                style={{ width: 220 }}
                onChange={async (
                  _val: number,
                  option: OptionItem | OptionItem[]
                ) => {
                  if (editMode) {
                    setSubmitting(true);
                    setSubmittingProp("pacaLevelId");

                    await onUpdate("pacaLevelId", (option as OptionItem).id);

                    setSubmittingProp(undefined);
                    setSubmitting(false);
                  } else {
                    setCurrentPriceConfigurator({
                      ...currentPriceConfigurator,
                      pacaLevelId: (option as OptionItem).id,
                    });
                  }
                }}
                options={masterInfoData?.pacaLevels}
              />
            </Space>
            <Space>
              <InputLabel label={t("input.material")} />
              <Select
                className="disabled-gray-text"
                loading={submittingProp === "materialId"}
                disabled={editMode && !editingUnlocked}
                value={
                  currentPriceConfigurator.materialId ??
                  currentPriceConfigurator.material?.id
                }
                fieldNames={{ value: "id", label: "label" }}
                style={{ width: 220 }}
                onChange={async (
                  _val: number,
                  option: OptionItem | OptionItem[]
                ) => {
                  if (editMode) {
                    actionWithConfirm(
                      t("confirm.masterInfoEditingMaterialConfirmTitle"),
                      t("confirm.masterInfoEditingMaterialConfirmContent"),
                      async () => {
                        setCurrentPriceConfigurator({
                          ...currentPriceConfigurator,
                          materialId: (option as OptionItem).id,
                        });

                        setSubmitting(true);
                        setSubmittingProp("materialId");

                        await onUpdate("materialId", (option as OptionItem).id);

                        setSubmittingProp(undefined);
                        setSubmitting(false);

                        setEditedInputs((prevState: any) => {
                          return { ...prevState, materialId: true };
                        });
                      },
                      t("button.continue"),
                      undefined,
                      editedInputs.materialId || isDataPreserved
                    );
                  } else {
                    setCurrentPriceConfigurator({
                      ...currentPriceConfigurator,
                      materialId: (option as OptionItem).id,
                    });
                  }
                }}
                options={masterInfoData?.materials}
              />
            </Space>
            <Space>
              <InputLabel label={t("input.modelType")} />
              <Select
                className="disabled-gray-text"
                loading={submittingProp === "modelTypeId"}
                disabled={editMode && !editingUnlocked}
                value={
                  currentPriceConfigurator.modelTypeId ??
                  currentPriceConfigurator.modelType?.id
                }
                fieldNames={{ value: "id", label: "label" }}
                style={{ width: 220 }}
                onChange={async (
                  _val: number,
                  option: OptionItem | OptionItem[]
                ) => {
                  if (editMode) {
                    actionWithConfirm(
                      t("confirm.masterInfoEditingCollectionConfirmTitle"),
                      isDataPreserved
                        ? t(
                            "confirm.masterInfoEditingCollectionInPreserveConfirmContent"
                          )
                        : t(
                            "confirm.masterInfoEditingCollectionConfirmContent"
                          ),
                      async () => {
                        setCurrentPriceConfigurator({
                          ...currentPriceConfigurator,
                          modelTypeId: (option as OptionItem).id,
                        });

                        setSubmitting(true);
                        setSubmittingProp("modelTypeId");

                        await onUpdate(
                          "modelTypeId",
                          (option as OptionItem).id
                        );

                        setSubmittingProp(undefined);
                        setSubmitting(false);

                        setEditedInputs((prevState: any) => {
                          return { ...prevState, modelTypeId: true };
                        });
                      },
                      t("button.continue"),
                      undefined,
                      editedInputs.modelTypeId
                    );
                  } else {
                    setCurrentPriceConfigurator({
                      ...currentPriceConfigurator,
                      modelTypeId: (option as OptionItem).id,
                    });
                  }
                }}
                options={masterInfoData?.modelTypes}
              />
            </Space>
            <Space>
              <InputLabel label={t("input.release")} />
              <Select
                className="disabled-gray-text"
                loading={submittingProp === "releaseId"}
                disabled={editMode && !editingUnlocked}
                value={
                  currentPriceConfigurator.releaseId ??
                  currentPriceConfigurator.release?.id
                }
                fieldNames={{ value: "id", label: "label" }}
                style={{ width: 220 }}
                onChange={async (
                  _val: number,
                  option: OptionItem | OptionItem[]
                ) => {
                  setCurrentPriceConfigurator({
                    ...currentPriceConfigurator,
                    releaseId: (option as OptionItem).id,
                  });

                  if (editMode) {
                    setSubmitting(true);
                    setSubmittingProp("releaseId");

                    await onUpdate("releaseId", (option as OptionItem).id);

                    setSubmittingProp(undefined);
                    setSubmitting(false);
                  }
                }}
                options={masterInfoData?.releases}
              />
            </Space>
            <Space>
              <InputLabel label={t("input.madeIn")} />
              <Select
                className="disabled-gray-text"
                loading={submittingProp === "madeInId"}
                disabled={editMode && !editingUnlocked}
                value={
                  currentPriceConfigurator.madeInId ??
                  currentPriceConfigurator.madeIn?.id
                }
                fieldNames={{ value: "id", label: "label" }}
                style={{ width: 220 }}
                onChange={async (
                  _val: number,
                  option: OptionItem | OptionItem[]
                ) => {
                  if (editMode) {
                    actionWithConfirm(
                      t("confirm.masterInfoEditingMadeInConfirmTitle"),
                      t("confirm.masterInfoEditingMadeInConfirmContent"),
                      async () => {
                        setCurrentPriceConfigurator({
                          ...currentPriceConfigurator,
                          madeInId: (option as OptionItem).id,
                        });

                        setSubmitting(true);
                        setSubmittingProp("madeInId");

                        await onUpdate("madeInId", (option as OptionItem).id);

                        setSubmittingProp(undefined);
                        setSubmitting(false);

                        setEditedInputs((prevState: any) => {
                          return { ...prevState, madeInId: true };
                        });
                      },
                      t("button.continue"),
                      undefined,
                      editedInputs.madeInId || isDataPreserved
                    );
                  } else {
                    setCurrentPriceConfigurator({
                      ...currentPriceConfigurator,
                      madeInId: (option as OptionItem).id,
                    });
                  }
                }}
                options={masterInfoData?.madeIns}
              />
            </Space>
            <Space>
              <InputLabel label={t("input.comment")} />
              <Tooltip
                title={t("message.inputError")}
                open={!validateInput(currentPriceConfigurator?.comment!)}
                placement="right"
                overlayStyle={{ fontSize: "12px" }}
              >
                <TextArea
                  className="disabled-gray-text"
                  disabled={editMode && !editingUnlocked}
                  value={currentPriceConfigurator.comment}
                  title={t("input.comment") as string}
                  name={t("input.comment") as string}
                  style={{
                    width: 220,
                    borderColor: !validateInput(
                      currentPriceConfigurator.comment as string
                    )
                      ? "red"
                      : undefined,
                  }}
                  rows={3}
                  maxLength={500}
                  onChange={async (_event) => {
                    setCurrentPriceConfigurator({
                      ...currentPriceConfigurator,
                      comment: _event.target.value,
                    });
                  }}
                  onBlur={
                    editMode
                      ? async (_event) => {
                          if (validateInput(_event.target.value as string)) {
                            setSubmitting(true);

                            await onUpdate("comment", _event.target.value);

                            setSubmitting(false);
                          } else {
                            return;
                          }
                        }
                      : undefined
                  }
                />
              </Tooltip>
            </Space>
            <IsReferenceInput
              editMode={editMode}
              editingUnlocked={editingUnlocked}
              onUpdate={useCallback(onUpdate, [onUpdate])}
            />
          </Space>
        </Col>
        <Col md={10} sm={12}>
          <Flex style={{ width: "80%" }} justify={"space-evenly"}>
            <Flex
              style={{
                width: "100%",
                flexDirection: "column",
                height: "100%",
              }}
              justify={"center"}
              align={"center"}
            >
              <Space align="start">
                <ModelImageViewerP21
                  modelNumber={currentPriceConfigurator?.modelNumber}
                  preview={true}
                  width={isMobile ? 320 : 380}
                  showArrows
                />
              </Space>
              <Flex
                style={{
                  width: "100%",
                  height: "100%",
                }}
                justify={"flex-end"}
                align={"center"}
              >
                {currentPriceConfigurator.id > 0 && (
                  <Space style={{ float: "right" }}>
                    {currentPriceConfigurator.id > 0 && (
                      <Button
                        style={{
                          float: "right",
                          width: 160,
                          marginTop: 20,
                          marginRight: 10,
                        }}
                        type="default"
                        icon={<UnlockOutlined />}
                        disabled={submitting || !dataIsValid || editingUnlocked}
                        onClick={async (_e) => {
                          if (
                            currentPriceConfigurator.isReference &&
                            !currentPriceConfigurator.isOneInFamily
                          ) {
                            openNotification(t("message.editReferencePaca"), {
                              top: 28,
                              paddingRight: 45,
                              color: "orange",
                            });
                            return;
                          } else {
                            handleInvalidatePreserve(_e);
                          }
                        }}
                      >
                        {t("button.editMasterInfo")}
                      </Button>
                    )}
                    <Button
                      style={{
                        float: "right",
                        width: 120,
                        marginTop: 20,
                        marginRight: 10,
                      }}
                      type="primary"
                      disabled={submitting || !dataIsValid || !inputDataIsValid}
                      onClick={async () => {
                        await onNextClick();
                      }}
                    >
                      {t("button.next")}
                    </Button>
                  </Space>
                )}

                {!currentPriceConfigurator.id && (
                  <Button
                    style={{
                      float: "right",
                      width: 120,
                      marginTop: 20,
                    }}
                    type="primary"
                    disabled={submitting || !dataIsValid}
                    onClick={async () => {
                      setSubmitting(true);

                      await onSaveClick();

                      setSubmitting(false);
                    }}
                  >
                    {!submitting && t("button.create")}
                    {submitting && <Spinner size="medium" />}
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Skeleton>
  );
};
