import { atom } from "recoil";
import { PriceConfiguratorData } from "src/types/priceConfigurator";

export interface IModelMergeData {
  data?: PriceConfiguratorData;
  priceConfiguratorId?: number;
  weight?: number;
  notes?: string;
  isError?: boolean;
}

export type PriceConfiguratorMergeFilterData = {
  modelNumber: string;
  prototypeNumber?: string;
  madeInId?: number;
  pacaLevelId?: number;
  standardYear?: string;
  modelTypeId?: number;
  materialId?: number;
  releaseId?: number;
};

export const model1MergeState = atom<IModelMergeData>({
  key: "model1MergeState",
  default: {},
});

export const model2MergeState = atom<IModelMergeData>({
  key: "model2MergeState",
  default: {},
});

export const mergeFilterState = atom<
  PriceConfiguratorMergeFilterData | undefined
>({
  key: "mergeFilterState",
  default: undefined,
});
