import { Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataSectionType } from "src/enums";
import { FrameAliases } from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/handlers/FrameAliases";
import {
  DataSectionAliasData,
  DataSectionData,
} from "src/types/priceConfigurator";

type Props = {
  aliasData: DataSectionAliasData;
  dataSection: DataSectionData;
};

export const DataSectionAliasHandler = (props: Props) => {
  const { dataSection, aliasData } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, [dataSection.sectionType]);

  return (
    <Space direction="vertical" size={0} className="full-width-space">
      {dataSection.sectionType === DataSectionType.FRAMEALIASES ? (
        <FrameAliases aliasData={aliasData} dataSection={dataSection} />
      ) : (
        t("message.notImplemented")
      )}
    </Space>
  );
};
