import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image, Menu, MenuProps, Space, Typography } from "antd";
import React, { CSSProperties } from "react";

const { Title } = Typography;

export type TopBarItem = Required<MenuProps>["items"][number];

export const getTopBarItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  onClick?: () => void,
  children?: TopBarItem[],
  theme?: "light" | "dark",
  type?: string,
  style?: CSSProperties
): TopBarItem => {
  return {
    key,
    icon,
    children,
    label,
    theme,
    onClick,
    type,
    style,
  } as TopBarItem;
};

type Props = {
  appName: string | React.ReactNode;
  displayName?: string;
  userAvatar?: Blob;
  userSubItems?: TopBarItem[];
  avatarOverride?: React.ReactNode;
  centerTitle?: string;
};

export const TopBar: React.FC<Props> = (props: Props) => {
  const {
    appName,
    userSubItems,
    userAvatar,
    displayName,
    centerTitle,
    avatarOverride,
  } = props;

  let avatarFallback = <UserOutlined style={{ fontSize: 18 }} />;

  if (displayName) {
    const firstName = displayName.split(" ")[0];
    const lastName = displayName.split(" ")[1] ?? "";

    avatarFallback = (
      <Avatar size={"large"}>
        {firstName.substring(0, 1)} {lastName.substring(0, 1)}
      </Avatar>
    );
  }

  const userMenuData: TopBarItem[] = [
    {
      style: { color: "white" },
      label: userAvatar ? (
        <Avatar
          size={"large"}
          src={<Image preview={false} src={URL.createObjectURL(userAvatar)} />}
        />
      ) : (
        avatarFallback
      ),
      key: "SubMenu",
      icon: undefined,
      children: userSubItems,
      theme: "light",
    },
  ];

  return (
    <React.Fragment>
      <Space
        align="start"
        style={{
          textAlign: "center",
          margin: "0 auto",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        {appName}
        {centerTitle && (
          <Title
            data-test-id="top-bar-center-title"
            level={3}
            style={{
              paddingTop: 22,
              fontSize: 17,
              color: "white",
            }}
          >
            {centerTitle}
          </Title>
        )}
        <Space>
          {avatarOverride ? (
            avatarOverride
          ) : (
            <Menu theme="dark" mode="horizontal" items={userMenuData} />
          )}
        </Space>
      </Space>
    </React.Fragment>
  );
};
