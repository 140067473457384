import { CloseCircleOutlined } from "@ant-design/icons";
import { Flex, Input, InputNumber, Typography } from "antd";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { SetterOrUpdater } from "recoil";
import { InputLabel } from "src/core/components/InputLabel";
import { validateInput } from "src/core/utils/input";
import { IModelMergeData } from "src/modules/PriceConfiguratorMergeDrawer/recoil/atoms";

type Props = {
  title: string;
  data: IModelMergeData;
  setData: SetterOrUpdater<IModelMergeData>;
};

const { Text } = Typography;

export const MergeModelBlock: React.FC<Props> = (props: Props) => {
  const { title, data, setData } = props;

  const { t } = useTranslation();

  return (
    <Flex vertical={true} gap={10}>
      <Text style={{ fontWeight: 600 }}>{title}</Text>
      <Flex align="center">
        <InputLabel fontWeight={400} label={`${t("input.pacaId")}:`} />
        <Input
          type="number"
          disabled={true}
          suffix={
            (data?.priceConfiguratorId ?? 0) > 0 ? (
              <CloseCircleOutlined
                style={{ color: "black" }}
                onClick={() => setData({})}
              />
            ) : undefined
          }
          step={1}
          style={{ width: 200, backgroundColor: "lightgray", color: "black" }}
          value={data?.priceConfiguratorId}
          allowClear={true}
        />
      </Flex>
      <Flex align="center">
        <InputLabel fontWeight={400} label={`${t("input.weight")}:`} />
        <InputNumber
          controls={true}
          precision={0}
          style={{ width: 200 }}
          min={1}
          value={data?.weight}
          onChange={(value: number | null) => {
            setData((prevData) => {
              return {
                ...prevData,
                weight: value ?? undefined,
              };
            });
          }}
        />
      </Flex>
      <Flex align="center">
        <InputLabel fontWeight={400} label={`${t("input.notes")}:`} />
        <Input.TextArea
          style={{ width: 200, borderColor: data?.isError ? "red" : undefined }}
          value={data?.notes ?? ""}
          onChange={(_ev: ChangeEvent<HTMLTextAreaElement>) => {
            setData((prevData) => {
              return {
                ...prevData,
                notes: _ev.target.value,
                isError: !validateInput(_ev.target.value),
              };
            });
          }}
        />
      </Flex>
    </Flex>
  );
};
