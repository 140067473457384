import { Alert, Card, Divider, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { aliasCommentService } from "src/core/services/priceConfiguratorServices/aliasCommentService";
import { validateInput } from "src/core/utils/input";

type Props = {
  aliasId: number;
  priceConfiguratorId: number;
  label: string;
};

const { TextArea } = Input;

export const AliasComment = (props: Props) => {
  const { aliasId, label, priceConfiguratorId } = props;

  const [textareaValue, setTextareaValue] = useState<string | undefined>("");
  const [initialValue, setInitialValue] = useState<string | undefined>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { accessToken, success, authLoaded } = useAuth();

  const { t } = useTranslation();

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setTextareaValue(event.target.value);

    const isValid = validateInput(event.target.value);
    setError(!isValid);
  };

  const handleTextareaBlur = async () => {
    if (error) return;
    // Trigger the API call ONLY if the input has changed
    if (textareaValue !== initialValue) {
      setLoading(true);

      await aliasCommentService
        .updateDataSectionAliasComment(
          accessToken,
          priceConfiguratorId,
          aliasId,
          {
            value: textareaValue,
          }
        )
        .catch(() => {
          setError(true);
        });

      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (authLoaded && success && aliasId) {
        setLoading(true);

        const response =
          await aliasCommentService.fetchDataSectionAliasComments(
            accessToken,
            priceConfiguratorId,
            aliasId
          );
        setTextareaValue(response?.value);
        setInitialValue(response?.value);
      }

      setLoading(false);
    })();
  }, [accessToken, authLoaded, aliasId, priceConfiguratorId, success]);

  return (
    <Space direction="vertical" size={0} style={{ width: "100%" }}>
      <Divider orientation="left" plain style={{ border: "gray" }}>
        {label}
      </Divider>
      <Space
        size={50}
        style={{ width: "100%", display: "block", position: "relative" }}
      >
        <Card
          bodyStyle={{ padding: "0px" }}
          style={{
            width: "100%",
            padding: "2px",
            borderColor: error ? "red" : "lightgray",
          }}
        >
          {loading && !initialValue ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "145px",
              }}
            >
              {<Spinner />}
            </div>
          ) : (
            <TextArea
              rows={5}
              maxLength={500}
              disabled={loading}
              autoSize={{ minRows: 6, maxRows: 6 }}
              style={{
                width: "100%",
                borderColor: error ? "red" : "lightgray",
              }}
              value={textareaValue}
              onChange={handleTextareaChange}
              onBlur={handleTextareaBlur}
              onFocus={() => setInitialValue(textareaValue)}
            />
          )}
        </Card>
        {loading && !error && textareaValue && (
          <div style={{ position: "absolute", right: "10px", top: "10px" }}>
            <Spinner size="medium" />
          </div>
        )}
        {error && (
          <Alert
            style={{ marginTop: "5px" }}
            message={t("message.inputError")}
            type="error"
          />
        )}
      </Space>
    </Space>
  );
};
