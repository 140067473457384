import { Collapse, Skeleton, Space, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { dataSectionDataAliasService } from "src/core/services/priceConfiguratorServices/dataSectionDataAliasService";
import { dataSectionDataService } from "src/core/services/priceConfiguratorServices/dataSectionDataService";
import { AcetateAliasDataTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/AcetateAliasDataTable";
import { AcetateAliasInfoBlock } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/AcetateAliasInfoBlock";
import { AddAcetateAliasBlock } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/AddAcetateAliasBlock";
import { FrameAcetateTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/FrameAcetateTable";
import { OrderFormTable } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/FrameAcetate/components/OrderFormTable/OrderFormTable";
import {
  currentPriceConfiguratorState,
  fatherCodesState,
} from "src/recoil/atoms";
import {
  DataSectionData,
  FrameAcetateData,
  FrameAcetateOrderFormData,
  FrameAcetateOrderFormFatherCode,
  SectionDataAliasData,
} from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

const { Text } = Typography;

export const FrameAcetate = (props: Props) => {
  const { dataSection } = props;

  const { accessToken, success, authLoaded } = useAuth();
  const { t } = useTranslation();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);
  const [mainDataFetched, setMainDataFetched] = useState(false);
  const [mainDataFetching, setMainDataFetching] = useState(false);
  const [dataRefreshing, setDataRefreshing] = useState(false);

  const [frameAcetateData, setFrameAcetateData] = useState<FrameAcetateData>();
  const [orderFormData, setOrderFormData] =
    useState<FrameAcetateOrderFormData>();
  const [sectionDataAliases, setSectionDataAliases] =
    useState<SectionDataAliasData[]>();

  const [contentItems, setContentItems] = useState<any[]>([]);

  const setGlobalFatherCodes = useSetRecoilState(fatherCodesState);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        const response = await dataSectionDataService.fetchFrameAcetateData(
          accessToken,
          currentPriceConfigurator?.id ?? 0,
          currentPriceConfigurator.modelNumber ?? ""
        );

        setFrameAcetateData(response);

        setGlobalFatherCodes({ dataSectionId: 0, fatherCodes: [] });

        setMainDataFetched(true);
        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator?.id,
    currentPriceConfigurator.modelNumber,
    setGlobalFatherCodes,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success && mainDataFetched) {
        const response = await dataSectionDataService.fetchOrderFormData(
          accessToken,
          currentPriceConfigurator?.id ?? 0,
          currentPriceConfigurator.modelNumber ?? ""
        );

        setOrderFormData(response);

        setGlobalFatherCodes((prevState) => {
          return {
            dataSectionId: dataSection.id,
            fatherCodes: [
              ...prevState.fatherCodes,
              ...response.fatherCodes.filter(
                (code: FrameAcetateOrderFormFatherCode) => code.isSelected
              ),
            ],
          };
        });
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator?.id,
    currentPriceConfigurator.modelNumber,
    dataSection.id,
    mainDataFetched,
    setGlobalFatherCodes,
    success,
  ]);

  useEffect(() => {
    (async () => {
      if (authLoaded && success && mainDataFetched) {
        const aliasesResponse =
          await dataSectionDataAliasService.fetchSectionDataAliases(
            accessToken,
            currentPriceConfigurator?.id ?? 0,
            currentPriceConfigurator.modelNumber ?? ""
          );

        setSectionDataAliases(aliasesResponse);

        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator?.id,
    currentPriceConfigurator.modelNumber,
    mainDataFetched,
    success,
  ]);

  const refreshFrameAcetateData = useCallback(async () => {
    setMainDataFetching(true);

    const response = await dataSectionDataService.fetchFrameAcetateData(
      accessToken,
      currentPriceConfigurator?.id ?? 0,
      currentPriceConfigurator.modelNumber ?? ""
    );

    setFrameAcetateData(response);

    const orderFormResponse = await dataSectionDataService.fetchOrderFormData(
      accessToken,
      currentPriceConfigurator?.id ?? 0,
      currentPriceConfigurator.modelNumber ?? ""
    );

    setOrderFormData(orderFormResponse);

    setMainDataFetching(false);
  }, [
    accessToken,
    currentPriceConfigurator?.id,
    currentPriceConfigurator.modelNumber,
  ]);

  const handleOrderFormFatherItemChange = useCallback(
    async (value: FrameAcetateOrderFormFatherCode, isSelected: boolean) => {
      setDataRefreshing(true);

      await dataSectionDataService.updateOrderFormFatherSelection(
        accessToken,
        currentPriceConfigurator?.id ?? 0,
        currentPriceConfigurator.modelNumber ?? "",
        { ...value, isSelected: isSelected }
      );

      await refreshFrameAcetateData();

      setDataRefreshing(false);
    },
    [
      accessToken,
      currentPriceConfigurator?.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
    ]
  );

  const handleAliasOrderFormFatherItemChange = useCallback(
    async (
      aliasId: number,
      value: FrameAcetateOrderFormFatherCode,
      isSelected: boolean
    ) => {
      setDataRefreshing(true);

      await dataSectionDataAliasService.updateSectionAliasFatherSelection(
        accessToken,
        currentPriceConfigurator?.id ?? 0,
        currentPriceConfigurator.modelNumber ?? "",
        aliasId,
        { ...value, isSelected: isSelected }
      );

      await refreshFrameAcetateData();

      setDataRefreshing(false);
    },
    [
      accessToken,
      currentPriceConfigurator?.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
    ]
  );

  const handleOrderTableItemSelect = useCallback(
    async (itemId: number, isSelected: boolean) => {
      setDataRefreshing(true);

      await dataSectionDataService.updateOrderFormItemSelection(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        itemId,
        isSelected
      );

      await refreshFrameAcetateData();

      setDataRefreshing(false);
    },
    [
      accessToken,
      currentPriceConfigurator.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
    ]
  );

  const handleOrderTableAllItemsSelectUnselect = useCallback(
    async (isSelected: boolean) => {
      setDataRefreshing(true);

      await dataSectionDataService.updateFrameAcetateOrderFormItemsSelection(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        isSelected
      );

      await refreshFrameAcetateData();

      setDataRefreshing(false);
    },
    [
      accessToken,
      currentPriceConfigurator.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
    ]
  );

  const handleOrderTableMultipleItemsSelectUnselect = useCallback(
    async (idsList: number[], isSelected: boolean) => {
      setDataRefreshing(true);

      await dataSectionDataService.updateFrameAcetateOrderFormMultipleItemsSelection(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        idsList,
        isSelected
      );

      await refreshFrameAcetateData();

      setDataRefreshing(false);
    },
    [
      accessToken,
      currentPriceConfigurator.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
    ]
  );

  const onAddSectionDataAlias = async (aliasModelNumber: string) => {
    if (sectionDataAliases) {
      const newId = await dataSectionDataAliasService.createAcetateAlias(
        accessToken,
        currentPriceConfigurator?.id ?? 0,
        currentPriceConfigurator.modelNumber ?? "",
        aliasModelNumber
      );

      setSectionDataAliases([
        ...sectionDataAliases,
        {
          id: newId.id,
          aliasModelNumber: aliasModelNumber,
        },
      ]);
    }
  };

  const onRemoveSectionDataAlias = useCallback(
    async (aliasId: number) => {
      if (sectionDataAliases) {
        await dataSectionDataAliasService.deleteAcetateAlias(
          accessToken,
          currentPriceConfigurator?.id ?? 0,
          currentPriceConfigurator.modelNumber ?? "",
          aliasId
        );

        const updatedList = sectionDataAliases.filter(
          (item: SectionDataAliasData) => item.id !== aliasId
        );

        setSectionDataAliases(updatedList);

        setGlobalFatherCodes((prevState) => {
          return {
            ...prevState,
            fatherCodes: prevState.fatherCodes.filter(
              (code: FrameAcetateOrderFormFatherCode) =>
                code.aliasId !== aliasId
            ),
          };
        });

        await refreshFrameAcetateData();
      }
    },
    [
      accessToken,
      currentPriceConfigurator?.id,
      currentPriceConfigurator.modelNumber,
      refreshFrameAcetateData,
      sectionDataAliases,
      setGlobalFatherCodes,
    ]
  );

  useEffect(() => {
    let itemsList = [
      {
        label: (
          <Title level={4} style={{ marginBottom: 0 }}>
            {t("dataSection.orderFormTitleTitle")}
          </Title>
        ),
        key: "order-form",
        children: (
          <React.Fragment>
            <Title level={5} style={{ marginBottom: 10, fontWeight: 400 }}>
              {t("label.model")}: {currentPriceConfigurator.modelNumber}
            </Title>
            {orderFormData && (
              <OrderFormTable
                orderFormItems={orderFormData.orderFormItems}
                fatherCodes={orderFormData.fatherCodes}
                handleOrderFormFatherItemChange={
                  handleOrderFormFatherItemChange
                }
                handleOrderTableItemSelect={handleOrderTableItemSelect}
                handleOrderTableAllItemsSelectUnselect={
                  handleOrderTableAllItemsSelectUnselect
                }
                handleOrderTableMultipleItemsSelectUnselect={
                  handleOrderTableMultipleItemsSelectUnselect
                }
                dataRefreshing={dataRefreshing}
                onDataChange={refreshFrameAcetateData}
              />
            )}
          </React.Fragment>
        ),
      },
    ];

    sectionDataAliases?.forEach((alias: SectionDataAliasData, index) => {
      itemsList.push({
        label: (
          <AcetateAliasInfoBlock
            aliasData={alias}
            index={index}
            onAliasRemove={onRemoveSectionDataAlias}
          />
        ),
        key: `aliases-section-${index}`,
        children: (
          <Space
            key={`data-section-alias-${index}`}
            direction="vertical"
            style={{ marginTop: index > 0 ? 25 : 0 }}
          >
            <Text
              style={{
                display: "block",
                color: "black",
                marginBottom: 5,
                fontWeight: 600,
              }}
            >
              {t("label.alias")}: {alias.aliasModelNumber}
            </Text>
            <AcetateAliasDataTable
              priceConfiguratorId={currentPriceConfigurator.id ?? 0}
              dataSectionId={dataSection.id}
              modelNumber={currentPriceConfigurator.modelNumber ?? ""}
              aliasModelNumber={alias.aliasModelNumber}
              aliasId={alias.id}
              handleOrderFormFatherItemChange={
                handleAliasOrderFormFatherItemChange
              }
              onAliasItemSelectionChangeSuccess={refreshFrameAcetateData}
            />
          </Space>
        ),
      });
    });

    setContentItems(itemsList);
  }, [
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    dataRefreshing,
    dataSection.id,
    handleAliasOrderFormFatherItemChange,
    handleOrderFormFatherItemChange,
    handleOrderTableAllItemsSelectUnselect,
    handleOrderTableItemSelect,
    handleOrderTableMultipleItemsSelectUnselect,
    onRemoveSectionDataAlias,
    orderFormData,
    refreshFrameAcetateData,
    sectionDataAliases,
    t,
  ]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        {frameAcetateData && (
          <FrameAcetateTable
            data={frameAcetateData}
            dataRefreshing={dataRefreshing || mainDataFetching}
          />
        )}
        <Collapse
          defaultActiveKey={[
            "order-form",
            "aliases-section-0",
            "aliases-section-1",
            "aliases-section-2",
            "aliases-section-3",
          ]}
          items={contentItems}
          ghost
        />
        {sectionDataAliases && (
          <AddAcetateAliasBlock onAliasAdd={onAddSectionDataAlias} />
        )}
      </Skeleton>
    </Space>
  );
};
