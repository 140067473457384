import { DeleteOutlined } from "@ant-design/icons";
import { Input, InputNumber, Select, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { lensesService } from "src/core/services/priceConfiguratorServices/lensesService";
import { manualDataService } from "src/core/services/priceConfiguratorServices/manualDataService";
import { numberSorter } from "src/core/utils/sort";
import { LensSunTypeEnum, PlasticTypeEnum } from "src/enums";
import { OrderFormManualTextInput } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/components/OrderFormManualTextInput";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import {
  currentPriceConfiguratorState,
  currentUserState,
  masterInfoState,
} from "src/recoil/atoms";
import { OptionItem } from "src/types/common";
import { SunLensesOrderFormDataItem } from "src/types/priceConfigurator";

export function useSunLensesOrderFormColumns(
  dataSectionId: number,
  dataItems: SunLensesOrderFormDataItem[],
  setDataItems: (items: SunLensesOrderFormDataItem[]) => void,
  selectedPlasticType: PlasticTypeEnum,
  unsavedRecord: SunLensesOrderFormDataItem | undefined,
  savingUnsavedRecordId: number | undefined,
  setSavingUnsavedRecordId: (id: number | undefined) => void,
  setUnsavedRecord: (record: SunLensesOrderFormDataItem | undefined) => void,
  onDataChange: () => Promise<void>,
  onRecordAddRemove: () => Promise<void>,
  setTableLoading: (loading: boolean) => void
) {
  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const masterInfoData = useRecoilValue(masterInfoState);
  const currentUser = useRecoilValue(currentUserState);

  const { accessToken } = useAuth();
  const { t } = useTranslation();

  const getTableColumns = useCallback(() => {
    const getLensMaterials = () => {
      return masterInfoData?.manualLensMaterials?.filter(
        (item) =>
          item.lensesType ===
            (selectedPlasticType as unknown as LensSunTypeEnum) ||
          item.lensesType === LensSunTypeEnum.CRYSTALLENS
      );
    };

    const saveRecord = async (
      record: SunLensesOrderFormDataItem,
      property: string,
      value: string | number | undefined | boolean,
      index: number,
      property2?: string,
      value2?: string | number | undefined | null,
      property3?: string,
      value3?: string | number | undefined | null
    ) => {
      setSavingUnsavedRecordId(record.id);

      const recordUpdateData = property2
        ? property3
          ? {
              ...record,
              [property]: value,
              [property2]: value2,
              [property3]: value3,
            }
          : { ...record, [property]: value, [property2]: value2 }
        : { ...record, [property]: value };

      let updatedDataItems = [...dataItems];

      updatedDataItems.splice(index, 1, recordUpdateData);
      setDataItems(updatedDataItems);

      if (record.id > 0) {
        await manualDataService.updateSunLensesOrderFormManualInputItem(
          accessToken,
          currentPriceConfigurator.id,
          dataSectionId,
          record.id,
          recordUpdateData
        );

        let updatedRecord =
          await manualDataService.fetchSunLensesOrderFormManualInputItem(
            accessToken,
            currentPriceConfigurator.id,
            dataSectionId,
            record.id
          );

        let databaseUpdatedDataItems = [...dataItems];

        databaseUpdatedDataItems.splice(index, 1, updatedRecord);

        setDataItems(databaseUpdatedDataItems);
      } else {
        if (unsavedRecord) {
          setUnsavedRecord({ ...unsavedRecord, [property]: value });
        }
      }

      setSavingUnsavedRecordId(undefined);

      await onDataChange();
    };

    const saveLensCost = async (
      item: SunLensesOrderFormDataItem,
      value: string | undefined,
      index: number
    ) => {
      if (item.currencyCode) {
        await saveRecord(
          item,
          "lensCost",
          value ? Number(value) : undefined,
          index
        );
      } else {
        await saveRecord(
          item,
          "lensCost",
          value ? Number(value) : undefined,
          index,
          "currencyCode",
          currentUser.currency?.currencyCode
        );
      }
    };

    const deleteUnsavedRecord = () => {
      setDataItems(dataItems.filter((item) => item.id > 0));
      setUnsavedRecord(undefined);
    };

    const updateTableRecordMadeIn = async (
      itemId: number,
      madeInId: number
    ) => {
      setTableLoading(true);

      await lensesService.updateLensOrderFormItemMadeIn(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        itemId,
        madeInId
      );

      const updatedRecord = await lensesService.fetchLensOrderFormItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        itemId
      );

      let currentDataItems = dataItems.filter(
        (item: SunLensesOrderFormDataItem) => item.id !== itemId
      );

      currentDataItems.push(updatedRecord);
      currentDataItems = currentDataItems.sort(
        (a: SunLensesOrderFormDataItem, b: SunLensesOrderFormDataItem) => {
          return numberSorter(a.id, b.id);
        }
      );

      setDataItems(currentDataItems);

      setTableLoading(false);

      await onDataChange();
    };

    const columns: ColumnsType<SunLensesOrderFormDataItem> = [
      Table.SELECTION_COLUMN,
      {
        title: t("tableColumn.colorCode"),
        key: "l_sun_column",
        align: "center",
        width: 100,
        render: (item: SunLensesOrderFormDataItem) => item.colorCode,
      },
      {
        title: t("tableColumn.lensDescription"),
        key: "l_lens_description_column",
        align: "center",
        width: 120,
        render: (item: SunLensesOrderFormDataItem) => item.lensDescription,
      },
      {
        title: t("tableColumn.lensMaterial"),
        key: "l_lens_material_column",
        align: "center",
        width: 120,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index
        ) =>
          item.isCorrection ? (
            <Select
              disabled={!!item.clenId}
              value={
                item.id > 0 ? item.lensMaterial : unsavedRecord?.lensMaterial
              }
              style={{ width: "100%" }}
              fieldNames={{ value: "label", label: "label" }}
              onChange={async (_val: string) => {
                await saveRecord(item, "lensMaterial", _val, index);
              }}
              options={getLensMaterials()}
            />
          ) : item.lensCost === null ? null : (
            value.lensMaterial
          ),
      },
      {
        title: t("tableColumn.lensType"),
        key: "l_type_column",
        align: "center",
        width: 100,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index
        ) =>
          value.isCorrection ? (
            <Select
              value={item.id > 0 ? item.lensType : unsavedRecord?.lensType}
              style={{ width: "100%" }}
              fieldNames={{ value: "label", label: "label" }}
              onChange={async (_val: string) => {
                await saveRecord(item, "lensType", _val, index);
              }}
              options={masterInfoData?.lensTypes}
            />
          ) : (
            value.lensType
          ),
      },
      {
        title: t("tableColumn.photochromic"),
        key: "l_photochromic_column",
        align: "center",
        width: 120,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index
        ) =>
          value.isCorrection ? (
            <Select
              allowClear
              value={
                item.id > 0 ? item.photochromic : unsavedRecord?.photochromic
              }
              style={{ width: "100%" }}
              fieldNames={{ value: "label", label: "label" }}
              onChange={async (_val: string) => {
                await saveRecord(item, "photochromic", _val, index);
              }}
              options={masterInfoData?.photochromic}
            />
          ) : (
            value.photochromic
          ),
      },
      {
        title: t("tableColumn.clenId"),
        key: "l_clen_id_column",
        align: "center",
        width: 100,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index
        ) => (
          <OrderFormManualTextInput
            item={item}
            propName="clenId"
            saveRecord={saveRecord}
            index={index}
            useOnBlur={true}
            useOnPressEnter={true}
          />
        ),
      },
      {
        title: t("tableColumn.madeIn"),
        key: "l_made_in_column",
        align: "center",
        width: 140,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index
        ) => (
          <Select
            value={item.madeInId === 0 ? undefined : item.madeInId}
            style={{ width: "100%" }}
            fieldNames={{ value: "id", label: "label" }}
            onChange={async (
              _val: number,
              option: OptionItem | OptionItem[]
            ) => {
              await updateTableRecordMadeIn(item.id, _val);
            }}
            options={masterInfoData?.madeIns}
          />
        ),
      },
      {
        title: t("tableColumn.lensCost"),
        key: "l_cost_column",
        align: "center",
        width: 80,
        render: (
          value: SunLensesOrderFormDataItem,
          item: SunLensesOrderFormDataItem,
          index: number
        ) =>
          item.isCorrection ? (
            <Space>
              {!!item.clenId && item.lensCost === null ? (
                <Input disabled={true} value={"N/A"} style={{ width: 70 }} />
              ) : (
                <InputNumber
                  disabled={!!item.clenId}
                  value={item.id > 0 ? item?.lensCost : unsavedRecord?.lensCost}
                  precision={2}
                  placeholder="0.00"
                  min={0}
                  style={{ width: 70 }}
                  controls={false}
                  onPressEnter={async (_ev: any) => {
                    await saveLensCost(item, _ev.target.value, index);
                  }}
                  onBlur={async (_ev) => {
                    await saveLensCost(item, _ev.target.value, index);
                  }}
                />
              )}

              {item.currencyCode}
            </Space>
          ) : (
            <ValueWithCurrency
              value={item.lensCost}
              currencyCode={item.currencyCode}
              showNotAvailableIfNull={true}
              precision={2}
            />
          ),
      },
      {
        key: "manual-input-actions",
        width: 20,
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <Space size="middle">
            {record.id === savingUnsavedRecordId && <Spinner size="small" />}
            {record.isCorrection && record.id !== savingUnsavedRecordId && (
              <DeleteOutlined
                onClick={async () => {
                  if (record.id > 0) {
                    setSavingUnsavedRecordId(record.id);

                    await manualDataService.deleteSunLensesOrderFormManualInputItem(
                      accessToken,
                      currentPriceConfigurator.id,
                      currentPriceConfigurator.modelNumber,
                      record.id
                    );

                    setTableLoading(true);

                    setDataItems(
                      dataItems.filter((item) => item.id !== record.id)
                    );

                    await onRecordAddRemove();

                    setSavingUnsavedRecordId(undefined);
                    setTableLoading(false);

                    await onDataChange();
                  } else {
                    deleteUnsavedRecord();
                  }
                }}
                style={{ cursor: "pointer" }}
              />
            )}
          </Space>
        ),
      },
    ];

    return columns;
  }, [
    accessToken,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser.currency?.currencyCode,
    dataItems,
    dataSectionId,
    masterInfoData?.lensTypes,
    masterInfoData?.madeIns,
    masterInfoData?.manualLensMaterials,
    masterInfoData?.photochromic,
    onDataChange,
    onRecordAddRemove,
    savingUnsavedRecordId,
    selectedPlasticType,
    setDataItems,
    setSavingUnsavedRecordId,
    setTableLoading,
    setUnsavedRecord,
    t,
    unsavedRecord,
  ]);

  return {
    getTableColumns: getTableColumns,
  };
}
