import { Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FrameAliasesContext,
  FrameAliasesContextType,
} from "src/modules/PriceConfiguratorManager/components/DataSectionAliasHandler/context/FrameAliasesContext";
import { ValueWithCurrency } from "src/modules/ValueWithCurrency/ValueWithCurrency";
import { ModelSkuData, ModelSkuMasterData } from "src/types/priceConfigurator";

type Props = {
  data: ModelSkuMasterData;
  selectedSkuValue?: string;
  onSelect: (selected: ModelSkuData) => Promise<void>;
};

const { Text } = Typography;

export const ModelSkuTable = (props: Props) => {
  const { data, onSelect, selectedSkuValue } = props;

  const [tableLoading, setTableLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const { t } = useTranslation();

  const { billOfMaterialLoading, routeLoading } = React.useContext(
    FrameAliasesContext
  ) as FrameAliasesContextType;

  useEffect(() => {
    (async () => {
      if (selectedSkuValue) {
        setSelectedRowKeys([selectedSkuValue]);
      }
      setTableLoading(false);
    })();
  }, [selectedSkuValue]);

  const columns: ColumnsType<ModelSkuData> = [
    {
      title: t("tableColumn.sku"),
      key: "model_sku",
      align: "center",
      width: 220,
      render: (item: ModelSkuData) => item.sku,
    },
    {
      title: t("tableColumn.costLevel"),
      key: "model_cost_level",
      align: "center",
      render: (item: ModelSkuData) => item.costLevel,
    },
    {
      title: t("tableColumn.frameDirectCost"),
      key: "model_frame_direct_cost",
      align: "center",
      width: 220,
      render: (item: ModelSkuData) => (
        <ValueWithCurrency
          value={item.frameDirectCost}
          currencyCode={item.currencyCode}
        />
      ),
    },
    Table.SELECTION_COLUMN,
  ];

  const rowSelection = {
    onChange: async (
      selectedRowKey: React.Key[],
      selectedRows: ModelSkuData[]
    ) => {
      setTableLoading(true);

      await onSelect(selectedRows[0]);

      setTableLoading(false);
    },
  };

  return (
    <React.Fragment>
      <Table
        columns={columns}
        bordered
        loading={tableLoading || billOfMaterialLoading || routeLoading}
        dataSource={data.aliasDataSkus}
        pagination={false}
        rowKey={({ sku }) => `${sku}`}
        scroll={{ x: "max-content" }}
        footer={() => {
          return (
            <div style={{ width: "100%", textAlign: "right" }}>
              <Text strong>{t("tableFooter.averageTotalCost")}: </Text>
              <Text>
                <ValueWithCurrency
                  value={data?.averageTotalCost}
                  currencyCode={data.currencyCode}
                />
              </Text>
            </div>
          );
        }}
        rowSelection={{
          ...rowSelection,
          type: "radio",
          selectedRowKeys: selectedRowKeys,
        }}
        size={"small"}
      />
    </React.Fragment>
  );
};
