import { MenuFoldOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SidebarItem } from "src/core/components/LeftSidebar/LeftSidebar";
import { useAuth } from "src/core/hooks/useAuth";
import { useDefaultData } from "src/core/hooks/useDefaultData";
import { eventsService } from "src/core/services/priceConfiguratorServices/eventsService";
import { priceConfiguratorService } from "src/core/services/priceConfiguratorServices/priceConfiguratorService";
import { DataSectionType } from "src/enums";
import { AdminPage } from "src/modules/AdminPage/AdminPage";
import { PriceConfiguratorPage } from "src/modules/AdminSidebar/AdminSidebar";
import { SummaryDrawer } from "src/modules/PriceConfiguratorManager/components/SummaryDrawer/SummaryDrawer";
import { PriceConfiguratorManagerRenderer } from "src/modules/PriceConfiguratorManager/renderers/PriceConfiguratorManagerRenderer";
import {
  buildPriceConfiguratorSidebarItem,
  buildPriceConfiguratorSidebarItems,
} from "src/modules/PriceConfiguratorManager/utils/navigation";
import {
  currentPriceConfiguratorState,
  defaultPriceConfiguratorData,
} from "src/recoil/atoms";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  pageTitle: string;
  activePage: PriceConfiguratorPage;
  activeSectionType: DataSectionType;
  activeSectionIndex: number;
  headerButtons?: JSX.Element[];
  clearExistingData?: boolean;
};

export const PriceConfiguratorManager: React.FC<Props> = (props: Props) => {
  const {
    activePage,
    pageTitle,
    headerButtons,
    activeSectionType,
    activeSectionIndex,
    clearExistingData,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { accessToken, success, authLoaded } = useAuth();

  const [currentPriceConfigurator, setCurrentPriceConfigurator] =
    useRecoilState(currentPriceConfiguratorState);

  const [activeDataSectionId, setActiveDataSectionId] = useState<number>();

  const [loading, setLoading] = useState(true);
  const [dataRefreshing, setDataRefreshing] = useState(false);

  const [adminPageTitle, setAdminPageTitle] = useState(pageTitle);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [isDataPreserved, setIsDataPreserved] = useState<boolean>(false);

  const { defaultPriceConfiguratorSidebarSections } = useDefaultData();

  useEffect(() => {
    (async () => {
      if (authLoaded && success && loading) {
        if (!clearExistingData) {
          if (currentPriceConfigurator?.id) {
            // fetch Price Configurator
            const configurator =
              await priceConfiguratorService.fetchPriceConfigurator(
                accessToken,
                currentPriceConfigurator?.id
              );

            setCurrentPriceConfigurator(configurator);
          } else {
            navigate("/price-configurator");
          }
        } else {
          setCurrentPriceConfigurator(defaultPriceConfiguratorData);
        }

        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    clearExistingData,
    currentPriceConfigurator?.id,
    loading,
    navigate,
    setCurrentPriceConfigurator,
    success,
  ]);

  useEffect(() => {
    if (
      pageTitle === "" &&
      currentPriceConfigurator &&
      currentPriceConfigurator.sections
    ) {
      setAdminPageTitle(
        currentPriceConfigurator.sections[activeSectionIndex].name
      );
    }
  }, [currentPriceConfigurator, adminPageTitle, activeSectionIndex, pageTitle]);

  const getAdditionalSubPages = (): SidebarItem[] => {
    if (currentPriceConfigurator.id && currentPriceConfigurator.sections) {
      return buildPriceConfiguratorSidebarItems(
        currentPriceConfigurator.sections,
        onSectionClick,
        [
          buildPriceConfiguratorSidebarItem(
            {
              id: 0,
              name: t("dataSections.masterInfo"),
              sectionType: DataSectionType.MASTERINFO,
              sortOrder: 0,
            },
            () => {
              navigate("/price-configurator/manager/master-info");
            }
          ),
        ]
      );
    } else {
      return defaultPriceConfiguratorSidebarSections;
    }
  };

  useEffect(() => {
    if (
      currentPriceConfigurator.sections &&
      currentPriceConfigurator.sections.length > 0 &&
      currentPriceConfigurator.sections[activeSectionIndex]
    ) {
      setActiveDataSectionId(
        currentPriceConfigurator.sections[activeSectionIndex].id
      );
    }
  }, [activeSectionIndex, currentPriceConfigurator.sections]);

  const onSectionClick = async (
    section: DataSectionData,
    sectionIndex: number
  ) => {
    if (activeDataSectionId) {
      setDataRefreshing(true);

      navigate(
        `/price-configurator/manager/section/${section.sectionType}/${sectionIndex}`
      );

      eventsService
        .processDataSectionRefreshEvent(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          activeDataSectionId
        )
        .then(() => {
          setDataRefreshing(false);
        });

      setActiveDataSectionId(section.id);
    } else {
      setActiveDataSectionId(section.id);

      navigate(
        `/price-configurator/manager/section/${section.sectionType}/${sectionIndex}`
      );
    }
  };

  const onSaveClick = async () => {
    if (!currentPriceConfigurator.id) {
      // create action
      const createResult =
        await priceConfiguratorService.createPriceConfigurator(accessToken, {
          ...currentPriceConfigurator,
          modelNumber: currentPriceConfigurator.modelNumber?.trim(),
          prototypeNumber: currentPriceConfigurator.prototypeNumber?.trim(),
        });

      // fetch created item by ID
      const configurator =
        await priceConfiguratorService.fetchPriceConfigurator(
          accessToken,
          createResult.id
        );

      if (configurator) {
        setCurrentPriceConfigurator(configurator);

        if (configurator?.sections) {
          await onSectionClick(configurator?.sections[0], 0);
        }
      }
    }
  };

  const onUpdate = async (
    propName1: string,
    value1: number | string | boolean,
    propName2?: string | any,
    value2?: boolean
  ) => {
    if (currentPriceConfigurator.id > 0) {
      // update action
      if (propName1 === "isReference") {
        // special case for isReference prop update
        if (value1) {
          await priceConfiguratorService.setPriceConfiguratorAsReference(
            accessToken,
            currentPriceConfigurator.id
          );
        }
      } else {
        await priceConfiguratorService.updatePriceConfiguratorMasterInfo(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          isDataPreserved,
          {
            ...currentPriceConfigurator,
            [propName1]: value1,
            [propName2]: value2,
          }
        );
      }

      // fetch updated item by ID
      const configurator =
        await priceConfiguratorService.fetchPriceConfigurator(
          accessToken,
          currentPriceConfigurator.id
        );

      if (configurator) {
        setCurrentPriceConfigurator(configurator);
      }
    }
  };

  const onNextClick = async () => {
    if (currentPriceConfigurator && currentPriceConfigurator?.sections) {
      if (activeSectionType === DataSectionType.MASTERINFO) {
        await onSectionClick(currentPriceConfigurator?.sections[0], 0);
      } else {
        if (currentPriceConfigurator?.sections[activeSectionIndex + 1]) {
          await onSectionClick(
            currentPriceConfigurator?.sections[activeSectionIndex + 1],
            activeSectionIndex + 1
          );
        }
      }
    }
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  return (
    <AdminPage
      pageTitle={adminPageTitle}
      activePage={activePage}
      headerButtons={headerButtons}
      contentLoading={loading || dataRefreshing}
      pageToAddChilds="price-configurator"
      childSidebarItems={getAdditionalSubPages()}
      centerTitle={
        currentPriceConfigurator.id
          ? currentPriceConfigurator.modelNumber
          : t("message.createNewPaca") ?? ""
      }
    >
      <React.Fragment>
        {currentPriceConfigurator.id > 0 && (
          <FloatButton
            onClick={() => showDrawer()}
            icon={<MenuFoldOutlined />}
            style={{ top: 75 }}
          />
        )}
      </React.Fragment>
      <SummaryDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
      <PriceConfiguratorManagerRenderer
        activeSectionType={activeSectionType}
        activeSectionIndex={activeSectionIndex}
        isDataPreserved={isDataPreserved}
        setIsDataPreserved={setIsDataPreserved}
        onSaveClick={onSaveClick}
        onNextClick={onNextClick}
        onUpdate={onUpdate}
      />
    </AdminPage>
  );
};
