import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Logo from "src/assets/imgs/logo.png";
import { TopBar } from "src/core/components/TopBar/TopBar";
import { useAuth } from "src/core/hooks/useAuth";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { userService } from "src/core/services/userService";
import { AppName } from "src/modules/AdminTopBar/components/AppName";
import { UserAvatar } from "src/modules/AdminTopBar/components/UserAvatar";

type Props = {
  centerTitle?: string;
  preNameBlock?: React.ReactNode;
  showOnlyLogo?: boolean;
};

export const AdminTopBar: React.FC<Props> = (props: Props) => {
  const { centerTitle, preNameBlock, showOnlyLogo } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useViewPortQuery() === "mobile";

  const { instance } = useMsal();
  const { accessToken } = useAuth();

  return (
    <TopBar
      appName={
        <AppName
          appName={t("common.appName")}
          appNameWidth={isMobile ? 180 : 200}
          onClick={() => {
            navigate("/");
          }}
          appLogo={Logo}
          preNameBlock={preNameBlock}
          showOnlyLogo={showOnlyLogo}
        />
      }
      avatarOverride={
        <UserAvatar
          displayName={instance.getActiveAccount()?.name}
          username={instance.getActiveAccount()?.username}
          signOut={() => {
            instance.logout();
          }}
          showCurrencyBeforeAvatar={true}
          onCurrencyUpdate={async (id: number) => {
            await userService.updateUserCurrency(accessToken, id);
          }}
        />
      }
      centerTitle={centerTitle}
    />
  );
};
