import { Skeleton, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import DownloadIcon from "src/assets/icons/DownloadIcon";
import { useAuth } from "src/core/hooks/useAuth";
import { applicationInsights } from "src/core/providers/TelemetryProvider/core/applicationInsights";
import { recapService } from "src/core/services/priceConfiguratorServices/recapService";
import { MadeInOriginSectionTypeEnum } from "src/enums";
import { RateAdjustmentsManager } from "src/modules/PriceConfiguratorManager/components/DataSectionManager/sections/MadeInOrigin/components/MadeInOriginManager/components/RateAdjustmentsManager";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { DataSectionData } from "src/types/priceConfigurator";

type Props = {
  dataSection: DataSectionData;
};

export const MadeInOriginManager = (props: Props) => {
  const { dataSection } = props;

  const { success, authLoaded, accessToken } = useAuth();
  const { t } = useTranslation();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);
  const [downloadingData, setDownloadingData] = useState(false);

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    success,
  ]);

  return (
    <Space
      direction="vertical"
      size={0}
      style={{ marginBottom: 50, minWidth: 600 }}
      className="full-width-space"
    >
      <Skeleton
        loading={loading}
        active
        style={{ minHeight: 200, minWidth: 200 }}
      >
        <Space direction="vertical">
          <div style={{ position: "relative", textAlign: "right" }}>
            {downloadingData ? (
              <Spin
                size="small"
                style={{
                  fontSize: 20,
                  position: "absolute",
                  cursor: "pointer",
                  right: 0,
                  top: -35,
                }}
              />
            ) : (
              <DownloadIcon
                style={{
                  position: "absolute",
                  width: 25,
                  cursor: "pointer",
                  right: 0,
                  top: -30,
                }}
                onClick={async () => {
                  setDownloadingData(true);
                  applicationInsights.trackPageView("Export_MI&O");

                  await recapService.downloadRecapData(
                    accessToken,
                    currentPriceConfigurator.id,
                    currentPriceConfigurator.modelNumber
                  );

                  setDownloadingData(false);
                }}
              />
            )}
          </div>

          <RateAdjustmentsManager
            type={MadeInOriginSectionTypeEnum.AVERAGESKUS}
            title={t("label.madeInOriginOnAverageSkus")}
            dataSection={dataSection}
          />
          <RateAdjustmentsManager
            type={MadeInOriginSectionTypeEnum.WORSTSKUS}
            title={t("label.madeInOriginOnWorstSkus")}
            dataSection={dataSection}
          />
        </Space>
      </Skeleton>
    </Space>
  );
};
