import { Skeleton, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useAuth } from "src/core/hooks/useAuth";
import { aliasService } from "src/core/services/priceConfiguratorServices/aliasService";
import { actionWithConfirm } from "src/core/utils/confirm";
import { EmptyDataSectionAlias } from "src/modules/PriceConfiguratorManager/components/DataSectionAlias/components/EmptyDataSectionAlias";
import { DataSectionAlias } from "src/modules/PriceConfiguratorManager/components/DataSectionAlias/DataSectionAlias";
import { currentPriceConfiguratorState } from "src/recoil/atoms";
import { ContainerTab } from "src/types/common";
import {
  DataSectionAliasData,
  DataSectionData,
} from "src/types/priceConfigurator";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

type Props = {
  dataSection: DataSectionData;
};

export const DataSectionTabsManager = (props: Props) => {
  const { dataSection } = props;

  const { t } = useTranslation();
  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );

  const [loading, setLoading] = useState(true);

  const [activeKey, setActiveKey] = useState<string>();
  const [items, setItems] = useState<ContainerTab[]>([]);

  const newTabIndex = useRef(0);

  // tab controls

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const add = () => {
    const newActiveKey = `alias-${newTabIndex.current++}`;
    const newPanes = [...items];
    newPanes.push({
      label: getTabName(items.length),
      children: <EmptyDataSectionAlias dataSection={dataSection} />,
      key: newActiveKey,
      closable: true,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const removeWithConfirm = async (targetKey: TargetKey) => {
    actionWithConfirm(
      t("confirm.deleteAliasTitle"),
      t("confirm.deleteAliasContent"),
      async () => {
        await remove(targetKey);
      }
    );
  };

  const remove = async (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;

    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const newPanes = items.filter((item) => item.key !== targetKey);

    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    const paneToDelete = items.find((item) => item.key === targetKey);

    if (paneToDelete && paneToDelete.data && paneToDelete.data?.id) {
      await aliasService.deleteDataSectionAlias(
        accessToken,
        paneToDelete?.data?.id,
        currentPriceConfigurator?.id ?? 0,
        dataSection.id
      );
    }

    newPanes.forEach((pane: ContainerTab, index) => {
      pane.label = getTabName(index);
    });

    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const getTabName = (index: number): string => {
    return `${t("label.alias")} ${index + 1}`;
  };

  const onEdit = async (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: "add" | "remove"
  ) => {
    if (action === "add") {
      add();
    } else {
      await removeWithConfirm(targetKey);
    }
  };

  // tab controls end

  useEffect(() => {
    (async () => {
      setItems([]);
    })();
  }, [dataSection.sectionType]);

  useEffect(() => {
    (async () => {
      if (success && authLoaded) {
        setLoading(true);

        if (items.length === 0) {
          // fetch data section alias
          const dataSectionAlias = await aliasService.fetchDataSectionAliases(
            accessToken,
            currentPriceConfigurator?.id ?? 0,
            dataSection.id
          );

          // no alias found
          if (dataSectionAlias.length === 0) {
            setItems([
              {
                label: "Alias 1",
                children: <EmptyDataSectionAlias dataSection={dataSection} />,
                key: "1",
                closable: false,
              },
            ]);
            setActiveKey("1");
          } else {
            // load existing alias
            let existingAliasTabs: ContainerTab[] = [];

            dataSectionAlias.forEach((item: DataSectionAliasData, index) => {
              existingAliasTabs.push({
                label: `Alias ${index + 1}`,
                children: (
                  <DataSectionAlias
                    aliasData={item}
                    dataSection={dataSection}
                  />
                ),
                key: `d-${dataSection.id}-a-${item.id}`,
                closable: index !== 0,
                data: item,
              });
            });

            setItems(existingAliasTabs);
            setActiveKey(existingAliasTabs[0].key);
          }
        }

        setLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator?.id,
    dataSection,
    items.length,
    success,
  ]);

  return (
    <Skeleton
      loading={loading}
      style={{ minHeight: 300, width: "60vw" }}
      active
    >
      <Tabs
        type="editable-card"
        onChange={onTabChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
        hideAdd={items.length === 10}
      />
    </Skeleton>
  );
};
