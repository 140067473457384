import { InfoCircleOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Input, InputNumber, Space, Tooltip, Typography } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Spinner } from "src/core/components/Spinner";
import { useAuth } from "src/core/hooks/useAuth";
import { madeInOriginService } from "src/core/services/priceConfiguratorServices/madeInOriginService";
import { calculatePropertySum } from "src/core/utils/array";
import { formatNumber } from "src/core/utils/format";
import { validateInput } from "src/core/utils/input";
import { MadeInOriginSectionTypeEnum } from "src/enums";
import {
  currentPriceConfiguratorState,
  currentUserState,
} from "src/recoil/atoms";
import { RateAdjustmentItem } from "src/types/priceConfigurator";

type Props = {
  type: MadeInOriginSectionTypeEnum;
  onLoad: () => Promise<void>;
  onDataChange: () => Promise<void>;
};

const { TextArea } = Input;

const { Text } = Typography;

export const RateAdjustmentsTable = (props: Props) => {
  const { onLoad, onDataChange, type } = props;

  const [tableLoading, setTableLoading] = useState(true);
  const [dataItems, setDataItems] = useState<RateAdjustmentItem[]>([]);

  const [unsavedRecord, setUnsavedRecord] = useState<RateAdjustmentItem>();

  const [savingUnsavedRecordId, setSavingUnsavedRecordId] = useState<number>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<RateAdjustmentItem[]>([]);

  const [note, setNote] = useState<any>({});
  const [validityStates, setValidityStates] = useState<Array<boolean>>([]);

  const { accessToken, success, authLoaded } = useAuth();

  const currentPriceConfigurator = useRecoilValue(
    currentPriceConfiguratorState
  );
  const currentUser = useRecoilValue(currentUserState);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (authLoaded && success) {
        if (dataItems.length === 0) {
          setTableLoading(true);

          const response = await madeInOriginService.fetchMasterInfoData(
            accessToken,
            currentPriceConfigurator.id,
            currentPriceConfigurator.modelNumber,
            type
          );

          setDataItems(response);

          await onLoad();

          setTableLoading(false);
        }
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    dataItems.length,
    onLoad,
    success,
    type,
  ]);

  useEffect(() => {
    (async () => {
      const preselectedItems: RateAdjustmentItem[] = dataItems.filter(
        (x: RateAdjustmentItem) => x.isSelected
      );

      const preselectedItemKeys: number[] = preselectedItems.map(
        (y: RateAdjustmentItem) => {
          return y.id;
        }
      );

      setSelectedRowKeys(preselectedItemKeys);
      setSelectedRows(preselectedItems);

      setTableLoading(false);
    })();
  }, [dataItems]);

  useEffect(() => {
    (async () => {
      // saves unsaved record then it is ready to be saved
      if (authLoaded && success && unsavedRecord) {
        setSavingUnsavedRecordId(unsavedRecord.id);
        setTableLoading(true);

        await madeInOriginService.saveRateAdjustmentItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          type,
          {
            ...unsavedRecord,
          }
        );

        const updatedRecords = await madeInOriginService.fetchMasterInfoData(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          type
        );

        setDataItems(updatedRecords);

        setSavingUnsavedRecordId(undefined);
        setUnsavedRecord(undefined);
        setTableLoading(false);
      }
    })();
  }, [
    accessToken,
    authLoaded,
    currentPriceConfigurator.id,
    currentPriceConfigurator.modelNumber,
    currentUser.currency?.currencyCode,
    success,
    type,
    unsavedRecord,
  ]);

  const getFirstCellRowSpan = (
    index: number,
    record: RateAdjustmentItem
  ): number => {
    if (dataItems && dataItems.length > 0 && !record.isCorrection) {
      if (
        dataItems[index - 1] &&
        dataItems[index - 1].priceConfiguratorSectionType ===
        record?.priceConfiguratorSectionType
      ) {
        return 0;
      }

      const result = dataItems.filter(
        (item: RateAdjustmentItem) =>
          item.priceConfiguratorSectionType ===
          record.priceConfiguratorSectionType
      ).length;

      return result;
    } else {
      return 1;
    }
  };

  const saveRecord = async (
    record: RateAdjustmentItem,
    property: string,
    value: string | number | undefined | boolean,
    index: number,
    property2?: string,
    value2?: string | number | undefined | null,
    property3?: string,
    value3?: string | number | undefined | null
  ) => {
    setSavingUnsavedRecordId(record.id);

    const recordUpdateData = property2
      ? property3
        ? {
          ...record,
          [property]: value,
          [property2]: value2,
          [property3]: value3,
        }
        : { ...record, [property]: value, [property2]: value2 }
      : { ...record, [property]: value };

    let updatedDataItems = [...dataItems];

    updatedDataItems.splice(index, 1, recordUpdateData);
    setDataItems(updatedDataItems);

    if (record.id > 0) {
      if (validateInput(value as string)) {
        const updatedRecord = await madeInOriginService.updateRateAdjustmentItem(
          accessToken,
          currentPriceConfigurator.id,
          currentPriceConfigurator.modelNumber,
          type,
          record.id,
          recordUpdateData
        );

        let databaseUpdatedDataItems = [...dataItems];

        databaseUpdatedDataItems.splice(index, 1, updatedRecord);

        setDataItems(databaseUpdatedDataItems);
      }
    }

    setSavingUnsavedRecordId(undefined);

    await onDataChange();
  };

  const columns: ColumnsType<RateAdjustmentItem> = [
    {
      title: t("tableColumn.section"),
      key: "section_column",
      align: "left",
      render: (item: RateAdjustmentItem, record, index) => {
        const val = item.id > 0 ? item.section : unsavedRecord?.section;

        return item.isCorrection ? (
          <Input
            defaultValue={val}
            title={t("tableColumn.section") as string}
            name={t("tableColumn.section") as string}
            style={{ width: "100%" }}
            status={!validateInput(val as string) ? "error" : undefined}
            suffix={
              !validateInput(val as string) ?
                <Tooltip title={t("message.inputError")}
                  defaultOpen
                  placement="right"
                  overlayStyle={{ fontSize: "12px" }}
                >
                  <InfoCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
                : <span />
            }
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "section", _ev.target.value, index);
            }}
            onBlur={async (_ev) => {
              await saveRecord(item, "section", _ev.target.value, index);
            }}
          />
        ) : (
          <b>
            {`${item.priceConfiguratorSectionType}. `}
            {item.section}
          </b>
        );
      },
      onCell: (record: RateAdjustmentItem, index: number | undefined) => ({
        rowSpan: getFirstCellRowSpan(index ?? 0, record),
      }),
    },
    {
      title: t("tableColumn.rateAjustmentComponents"),
      key: "components_column",
      align: "center",
      render: (item: RateAdjustmentItem, value, index) =>
        item.isCorrection ? (
          <Input
            defaultValue={
              item.id > 0 ? item.components : unsavedRecord?.components
            }
            style={{ width: "100%" }}
            title={t("tableColumn.rateAjustmentComponents") as string}
            name={t("tableColumn.rateAjustmentComponents") as string}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "components", _ev.target.value, index);
            }}
            onBlur={async (_ev) => {
              await saveRecord(item, "components", _ev.target.value, index);
            }}
          />
        ) : (
          item.components
        ),
    },
    {
      title: t("tableColumn.valType"),
      key: "valType_column",
      align: "center",
      width: 90,
      render: (item: RateAdjustmentItem, value, index) =>
        item.isCorrection ? (
          <Input
            defaultValue={item.id > 0 ? item.valType : unsavedRecord?.valType}
            style={{ width: "100%" }}
            title={t("tableColumn.valType") as string}
            name={t("tableColumn.valType") as string}
            onPressEnter={async (_ev: any) => {
              await saveRecord(item, "valType", _ev.target.value, index);
            }}
            onBlur={async (_ev) => {
              await saveRecord(item, "valType", _ev.target.value, index);
            }}
          />
        ) : (
          item.valType
        ),
    },
    {
      title: t("tableColumn.costKoInEuros"),
      key: "costKoInEuros_column",
      align: "center",
      render: (item: RateAdjustmentItem, value, index) =>
        type === MadeInOriginSectionTypeEnum.WORSTSKUS ? (
          <Space size={0}>
            <InputNumber
              value={item?.costKo}
              title={t("tableColumn.costKoInEuros") as string}
              name={t("tableColumn.costKoInEuros") as string}
              style={{ width: 70 }}
              precision={2}
              min={0}
              addonAfter={
                item.id === savingUnsavedRecordId && <Spinner size="small" />
              }
              controls={false}
              onPressEnter={async (_ev: any) => {
                await saveRecord(
                  item,
                  "costKo",
                  value ? Number(_ev.target.value) : undefined,
                  index
                );
              }}
              onBlur={async (_ev) => {
                await saveRecord(
                  item,
                  "costKo",
                  value ? Number(_ev.target.value) : undefined,
                  index
                );
              }}
            />
            {item.isCorrection && (
              <span style={{ marginLeft: 3 }}>{item.currencyCode}</span>
            )}
          </Space>
        ) : (
          formatNumber(item.costKo)
        ),
    },
    {
      title: t("tableColumn.erBdg"),
      key: "erBdg_column",
      align: "center",
      render: (item: RateAdjustmentItem, value, index) =>
        formatNumber(item.erBgd),
    },
    {
      title: t("tableColumn.erAdjusted"),
      key: "erAdjusted_column",
      align: "center",
      render: (item: RateAdjustmentItem, value, index) => (
        <InputNumber
          title={t("tableColumn.erAdjusted") as string}
          name={t("tableColumn.erAdjusted") as string}
          value={item?.erAdjusted}
          precision={2}
          min={0}
          addonAfter={
            item.id === savingUnsavedRecordId && <Spinner size="small" />
          }
          style={{ width: 70 }}
          controls={false}
          onPressEnter={async (_ev: any) => {
            await saveRecord(
              item,
              "erAdjusted",
              value ? Number(_ev.target.value) : undefined,
              index
            );
          }}
          onBlur={async (_ev) => {
            await saveRecord(
              item,
              "erAdjusted",
              value ? Number(_ev.target.value) : undefined,
              index
            );
          }}
        />
      ),
    },
    {
      title: t("tableColumn.costKoInEurosNewEr"),
      key: "costKoInEurosNewEr_column",
      align: "center",
      render: (item: RateAdjustmentItem, value, index) =>
        formatNumber(item.costKoNewEr),
    },
  ];

  const worstSkuColumns = [
    ...columns,
    ...[
      {
        title: t("tableColumn.notes"),
        key: "notes_column",
        align: "center",
        render: (
          item: RateAdjustmentItem,
          value: RateAdjustmentItem,
          index: number
        ) => {
          return (
            <div style={{ position: "relative", display: "flex" }}>
              <TextArea
                rows={1}
                maxLength={500}
                disabled={tableLoading}
                defaultValue={item?.notes ?? undefined}
                name={t("tableColumn.notes") as string}
                title={t("tableColumn.notes") as string}
                style={{
                  width: "100%",
                  borderColor:
                    !validateInput(item?.notes as string)
                      ? "red"
                      : undefined,
                }}
                onBlur={async (_ev) => {
                  await saveRecord(item, "notes", _ev.target.value, index);
                }}
              />
              {!validateInput(item?.notes as string) &&
                <Tooltip title={t("message.inputError")}
                  defaultOpen
                  placement="top"
                  overlayStyle={{ fontSize: "12px" }}>
                  <InfoCircleOutlined style={{ color: 'red', marginLeft: 6 }} />
                </Tooltip>
              }
            </div>
          )
        },
      },
      Table.SELECTION_COLUMN,
    ],
  ];

  const rowSelection = {
    onChange: (
      selectedRowKey: React.Key[],
      selectedRows: RateAdjustmentItem[]
    ) => {
      setSelectedRowKeys(selectedRowKey);
      setSelectedRows(selectedRows);
    },
    onSelect: async (item: RateAdjustmentItem, selected: boolean) => {
      setTableLoading(true);

      // handle item select/deselect
      await madeInOriginService.selectDeselectRateAdjustmentItem(
        accessToken,
        currentPriceConfigurator.id,
        currentPriceConfigurator.modelNumber,
        type,
        item.id,
        selected
      );

      const index = dataItems.findIndex((record) => record.id === item.id);

      let updatedDataItems = [...dataItems];

      updatedDataItems.splice(index, 1, { ...item, isSelected: selected });
      setDataItems(updatedDataItems);

      await onDataChange();

      setTableLoading(false);
    },
  };

  const handleAddClick = () => {
    const newRecord: RateAdjustmentItem = {
      id: -1,
      isCorrection: true,
    };

    setDataItems([...dataItems, ...[newRecord]]);
    setUnsavedRecord(newRecord);
  };

  return (
    <Space direction="vertical" className="full-width-space">
      {type === MadeInOriginSectionTypeEnum.WORSTSKUS && (
        <PlusSquareOutlined
          disabled={unsavedRecord !== undefined}
          onClick={unsavedRecord !== undefined ? undefined : handleAddClick}
          style={{
            width: 24,
            height: 24,
            fontSize: 24,
            color: unsavedRecord !== undefined ? "gray" : "black",
            marginBottom: 10,
            float: "left",
          }}
        />
      )}
      <Table
        columns={
          type === MadeInOriginSectionTypeEnum.AVERAGESKUS
            ? columns
            : worstSkuColumns
        }
        rowSelection={
          type === MadeInOriginSectionTypeEnum.WORSTSKUS
            ? {
              ...rowSelection,
              type: "checkbox",
              selectedRowKeys: selectedRowKeys,
              hideSelectAll: true,
            }
            : undefined
        }
        className="no-selection-background-table"
        loading={tableLoading}
        dataSource={dataItems}
        pagination={false}
        rowKey={(record) => record.id}
        scroll={{ x: "max-content" }}
        bordered={true}
        size={"small"}
        summary={(tableData) => {
          let totalCostKo = 0;
          let totalCostKoNewEr = 0;

          if (tableData.length > 0) {
            const rowsToSum =
              type === MadeInOriginSectionTypeEnum.AVERAGESKUS
                ? tableData
                : selectedRows;

            totalCostKo = calculatePropertySum([...rowsToSum] as [], "costKo");

            totalCostKoNewEr = calculatePropertySum(
              [...rowsToSum] as [],
              "costKoNewEr"
            );
          }

          return (
            <Table.Summary.Row style={{ textAlign: "center" }}>
              <Table.Summary.Cell colSpan={3} index={0}>
                <Text style={{ float: "left", fontWeight: 600 }}>
                  {t("tableFooter.total")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData && tableData.length > 0 && formatNumber(totalCostKo)}
              </Table.Summary.Cell>
              <Table.Summary.Cell colSpan={2} index={0}></Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                {tableData &&
                  tableData.length > 0 &&
                  formatNumber(totalCostKoNewEr)}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </Space>
  );
};
